import {Box, BoxProps} from '@mui/material';
import React, {ImgHTMLAttributes} from 'react';

type ImageComponentProps = Pick<
  ImgHTMLAttributes<never>,
  'src' | 'alt' | 'loading'
> &
  Pick<BoxProps, 'sx'>;

export const Image = ({src, alt, sx, ...rest}: ImageComponentProps) => {
  return <Box component={'img'} src={src} alt={alt} sx={sx} {...rest} />;
};
