import * as z from 'zod';

import dayjs from '@/lib/dayjs';

export const roleSchema = z.object({
  role: z.enum(['1', '2']).optional(),
});

export const accountSchema = z
  .object({
    name: z.string({
      required_error: 'Nama lengkap harus diisi',
    }),
    phone: z.string({
      required_error: 'Nomor telepon harus diisi',
    }),
    password: z
      .string({
        required_error: 'Password harus diisi',
      })
      .min(8, 'Password minimal 8 karakter'),
    confirm_password: z
      .string({
        required_error: 'Konfirmasi password harus diisi',
      })
      .min(8, 'Password minimal 8 karakter'),
    farmer_type_id: z
      .object(
        {
          id: z.string(),
          name: z.string(),
        },
        {
          required_error: 'Jenis Petani harus diisi',
        },
      )
      .nullable(),
  })
  .refine((value) => value.password === value.confirm_password, {
    message: 'Password tidak sama',
    path: ['confirm_password'],
  });

export const profileSchema = z.object({
  nik: z.string().length(16, 'NIK harus 16 karakter').nullish(),
  birth_date: z.instanceof(dayjs as unknown as typeof dayjs.Dayjs).nullable(),
  npwp: z.string().nullish(),
  npwp_address: z.string().nullable(),
  province_id: z.object({id: z.string(), name: z.string()}).nullable(),
  city_id: z
    .object({id: z.string(), name: z.string(), province_id: z.string()})
    .nullable(),
  regency_id: z
    .object({id: z.string(), name: z.string(), city_id: z.string()})
    .nullable(),
  district_id: z
    .object({id: z.string(), name: z.string(), regency_id: z.string()})
    .nullable(),
  address: z.string().nullable(),
});

export const regionSchema = z.object({
  region_id: z
    .object(
      {
        id: z.string(),
        name: z.string(),
        subject: z.string(),
      },
      {
        required_error: 'Daerah harus diisi',
      },
    )
    .nullable()
    .brand<'Region'>(),
  transaction_region_id: z
    .object(
      {
        id: z.string(),
        name: z.string(),
      },
      {
        required_error: 'Daerah Transaksi harus diisi',
      },
    )
    .nullable()
    .brand<'TransactionRegion'>(),
  latex_station_id: z
    .object(
      {
        id: z.string(),
        name: z.string(),
        subject: z.string(),
      },
      {
        required_error: 'Stasiun Latex harus diisi',
      },
    )
    .nullable()
    .refine((val) => val !== null, 'Stasiun Latex harus diisi'),
  land_area: z.coerce
    .number()
    .gte(0, 'Luas lahan harus lebih dari 0 ha')
    .nullable(),
  ancak_amount: z.coerce.number().gte(1, 'Jumlah Ancak minimal 1').nullable(),
  drs_started_at: z
    .instanceof(dayjs as unknown as typeof dayjs.Dayjs)
    .nullable(),
  drs_ended_at: z.instanceof(dayjs as unknown as typeof dayjs.Dayjs).nullable(),
  clone_type_id: z
    .object({
      id: z.string(),
      name: z.string(),
    })
    .nullable(),
  planting_year: z
    .instanceof(dayjs as unknown as typeof dayjs.Dayjs)
    .nullable(),
  principal_amount: z.coerce
    .number()
    .gte(0, 'Jumlah pokok harus diatas 0')
    .nullable(),
});

export const documentSchema = z.object({
  ktp: z.instanceof(File).nullish(),
  skt: z.instanceof(File).nullish(),
  skpl: z.instanceof(File).nullish(),
  truth_disclaimer: z.literal<boolean>(true),
});

export const registerSchema = roleSchema
  .merge(accountSchema.innerType())
  .merge(profileSchema)
  .merge(regionSchema)
  .merge(documentSchema)
  .transform((val) => {
    return {
      ...val,
      birth_date: val.birth_date?.format('YYYY-MM-DD') ?? null,
      drs_started_at: val.drs_started_at?.format('HH:mm:ss') ?? null,
      drs_ended_at: val.drs_ended_at?.format('HH:mm:ss') ?? null,
      planting_year: val.planting_year?.format('YYYY') ?? null,
      farmer_type_id: val.farmer_type_id?.id,
      province_id: val.province_id?.id,
      city_id: val.city_id?.id,
      regency_id: val.regency_id?.id,
      district_id: val.district_id?.id,
      region_id: val.region_id?.id,
      transaction_region_id: val.transaction_region_id?.id,
      latex_station_id: val.latex_station_id?.id,
      clone_type_id: val.clone_type_id?.id,
    };
  });

export type AccountSchema = z.input<typeof accountSchema>;

export type ProfileSchema = z.input<typeof profileSchema>;

export type RegionSchema = z.input<typeof regionSchema>;

export type DocumentSchema = z.input<typeof documentSchema>;

export type RegisterFormData = z.input<typeof registerSchema>;

export type RegisterCredentials = z.output<typeof registerSchema>;
