import {zodResolver} from '@hookform/resolvers/zod';
import {Close, InfoOutlined} from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  formHelperTextClasses,
} from '@mui/material';
import React from 'react';
import {useForm} from 'react-hook-form';
import * as z from 'zod';

import {TextHookField} from '@/components/Form';

const schema = z.object({
  name: z.string({
    required_error: 'Nama harus diisi',
  }),
});

type EditNameSchema = z.infer<typeof schema>;

export const EditNameDialog = ({
  open,
  onClose,
  onConfirm,
  values,
}: {
  open: boolean;
  onClose: () => void;
  onConfirm: (values: EditNameSchema) => void;
  values: EditNameSchema;
}) => {
  const {control, handleSubmit} = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      name: values?.name,
    },
  });

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={'tw_md'}>
      <DialogTitle>
        Nama
        <IconButton
          aria-label="close"
          onClick={() => {
            onClose();
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TextHookField
          name={'name'}
          label={'Nama Lengkap'}
          control={control}
          size={'small'}
          fullWidth
          margin={'normal'}
          helperText={
            <>
              <InfoOutlined
                sx={{
                  fontSize: 12,
                  verticalAlign: 'middle',
                  mr: 0.5,
                }}
              />
              Nama lengkap sesuai KTP
            </>
          }
          sx={{
            [`& .${formHelperTextClasses.root}`]: {
              marginX: 0,
            },
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant={'contained'}
          color={'primary'}
          onClick={handleSubmit((data) => {
            onConfirm(data);
          })}
        >
          Simpan
        </Button>
      </DialogActions>
    </Dialog>
  );
};
