import {create} from 'zustand';
import {devtools} from 'zustand/middleware';

import {RegisterFormData} from '../utils/schema';


export type RegisterStepSlug =
  | 'account'
  | 'profile'
  | 'region'
  | 'document'
  | 'success';

interface RegisterStore {
  formData: RegisterFormData;
  reset: () => void;
  setData: (data: Partial<RegisterFormData>) => void;
}

const formDefaults: RegisterFormData = {
  role: undefined,
  name: '',
  phone: '',
  password: '',
  confirm_password: '',
  farmer_type_id: null,
  nik: '',
  birth_date: null,
  province_id: null,
  city_id: null,
  regency_id: null,
  district_id: null,
  address: '',
  npwp: '',
  npwp_address: '',
  region_id: null,
  transaction_region_id: null,
  latex_station_id: null,
  land_area: 0,
  ancak_amount: 1,
  principal_amount: 0,
  clone_type_id: null,
  drs_ended_at: null,
  drs_started_at: null,
  planting_year: null,
  ktp: null,
  skt: null,
  skpl: null,
  truth_disclaimer: false,
};

export const useRegisterStore = create<RegisterStore>()(
  devtools((set) => ({
    formData: formDefaults,
    setData: (data) =>
      set((state) => ({
        formData: {
          ...state.formData,
          ...data,
        },
      })),
    reset: () =>
      set({
        formData: formDefaults,
      }),
  })),
);
