import {zodResolver} from '@hookform/resolvers/zod';
import {Close} from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import React from 'react';
import {useForm} from 'react-hook-form';
import * as z from 'zod';

import {TextHookField} from '@/components/Form';

const schema = z.object({
  npwp_address: z.string().nullable(),
});

type EditNpwpAddressSchema = z.infer<typeof schema>;

export const EditNpwpAddressDialog = ({
  open,
  onClose,
  onConfirm,
  values,
}: {
  open: boolean;
  onClose: () => void;
  onConfirm: (data: EditNpwpAddressSchema) => void;
  values?: EditNpwpAddressSchema;
}) => {
  const {control, handleSubmit} = useForm<EditNpwpAddressSchema>({
    resolver: zodResolver(schema),
    defaultValues: {
      npwp_address: values?.npwp_address ?? '',
    },
  });

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={'tw_md'}>
      <DialogTitle>
        Alamat NPWP
        <IconButton
          aria-label="close"
          onClick={() => {
            onClose();
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TextHookField
          name={'npwp_address'}
          label={'Alamat NPWP'}
          control={control}
          size={'small'}
          fullWidth
          margin={'normal'}
          maxRows={4}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant={'contained'}
          color={'primary'}
          onClick={handleSubmit((data) => {
            onConfirm(data);
          })}
        >
          Simpan
        </Button>
      </DialogActions>
    </Dialog>
  );
};
