import {useQuery} from '@tanstack/react-query';

import {FarmerType} from '@/features/replanting';

import api from '@/lib/axios';
import {QueryConfig} from '@/lib/react-query';


const getFarmerTypes = async (): Promise<FarmerType[]> => {
  const response = await api.get('/farmer-types');

  return response.data.data;
};

type UseFarmerTypesOptions = {
  config?: QueryConfig<typeof getFarmerTypes>;
};

export const useFarmerTypes = ({config}: UseFarmerTypesOptions = {}) => {
  return useQuery({
    queryKey: ['farmer-types'],
    queryFn: getFarmerTypes,
    ...config,
  });
};
