import {useQuery} from '@tanstack/react-query';

import {Province} from '@/features/regions';

import axios, {ApiResponse} from '@/lib/axios';
import {QueryConfig} from '@/lib/react-query';


const fetchProvinces = async (): Promise<Province[]> => {
  const response =
    await axios.get<ApiResponse<Province[]>>('/address/provinces');
  return response.data.data;
};

type UseProvincesOptions = {
  config?: QueryConfig<typeof fetchProvinces>;
};
export const useProvinces = ({config}: UseProvincesOptions = {}) => {
  return useQuery({
    queryKey: ['provinces'],
    queryFn: fetchProvinces,
    initialData: [],
    ...config,
  });
};
