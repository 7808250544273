import {AuthResponse, RegisterCredentials} from '@/features/auth';

import api, {ApiResponse} from '@/lib/axios';
import {generateFormData} from '@/utils/form-data';

export const register = async (
  data: RegisterCredentials,
): Promise<AuthResponse> => {
  const formData = generateFormData(data);

  const response = await api.post<ApiResponse<AuthResponse>>(
    '/register',
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );

  return response.data.data;
};
