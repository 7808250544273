import {ThemeOptions, createTheme} from '@mui/material';

const theme: ThemeOptions = {
  palette: {
    primary: {
      50: '#EAF4E4',
      100: '#EEFDF4',
      300: '#CDFFD2',
      600: '#3CB649',
      700: '#046C4E',
      900: '#046425',
      main: '#3CB649',
      contrastText: '#FFF',
    },
    secondary: {
      50: '#EEE683',
      100: '#B5D775',
      300: '#76C773',
      600: '#008A9F',
      900: '#006A9B',
      main: '#008A9F',
      contrastText: '#FFF',
    },
    neutral: {
      50: '#F9FAFB',
      100: '#F3F4F6',
      200: '#E5E7EB',
      300: '#D1D5DB',
      400: '#9CA3AF',
      500: '#6B7280',
      600: '#4B5563',
      700: '#374151',
      800: '#1F2937',
      900: '#111827',
      main: '#4B5563',
    },
    green: {
      700: '#046C4E',
    },
  },
  typography: {
    fontFamily: 'Poppins',
  },
  breakpoints: {
    values: {
      xs: 0,
      //Compatibility with Tailwind sizing
      tw_md: 448,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    // MuiContainer: {
    //   styleOverrides: {
    //     root: {paddingLeft: 0, paddingRight: 0},
    //   },
    // },
    // MuiChip: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: '#FFF',
    //       color: '#03B579',
    //     },
    //   },
    // },
    MuiDrawer: {
      styleOverrides: {
        paperAnchorBottom: {
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          padding: 8,
          maxWidth: '28rem',
          marginLeft: 'auto',
          marginRight: 'auto',
        },
      },
    },
  },
};

export const muiTheme = createTheme(theme);

export default theme;
