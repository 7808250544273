import {TextField, TextFieldProps} from '@mui/material';
import * as React from 'react';
import {Control, Controller, FieldValues, Path} from 'react-hook-form';

type TextHookFieldProps<T extends FieldValues> = {
  name: Path<T>;
  control: Control<T>;
  addSpaceOnEmptyHelperText?: boolean;
} & TextFieldProps;

export const TextHookField = <T extends FieldValues>({
  name,
  control,
  addSpaceOnEmptyHelperText = false,
  ...rest
}: TextHookFieldProps<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: {onChange, onBlur, value, name, disabled, ref},
        fieldState: {error},
      }) => (
        <TextField
          ref={ref}
          name={name}
          onChange={onChange}
          disabled={disabled}
          onBlur={onBlur}
          value={value}
          error={error !== undefined}
          helperText={error?.message ?? (addSpaceOnEmptyHelperText && ' ')}
          {...rest}
        />
      )}
    />
  );
};

export default TextHookField;
