import {TextField} from '@mui/material';
import * as React from 'react';
import {Control, FieldValues, Path} from 'react-hook-form';

import {Regency, useRegencies} from '@/features/regions';

import {AutocompleteHookField} from '@/components/Form';


export const RegencyAutocompleteField = <Fields extends FieldValues>({
  label,
  name,
  cityId,
  control,
  ...rest
}: {
  label: string;
  name: Path<Fields>;
  cityId?: string;
  fullWidth?: boolean;
  control: Control<Fields>;
}) => {
  const {data: regencies, isFetching} = useRegencies({
    cityId,
  });

  const isOptionEqualToValue = (option: Regency, value: Regency) => {
    return option.id === value.id;
  };

  const getOptionLabel = (option: Regency) => {
    return option.name;
  };

  return (
    <AutocompleteHookField
      {...rest}
      control={control}
      disabled={cityId === undefined}
      getOptionLabel={getOptionLabel}
      loading={isFetching}
      isOptionEqualToValue={isOptionEqualToValue}
      name={name}
      options={regencies ?? []}
      renderInput={(params) => {
        return <TextField label={label} {...params} />;
      }}
    />
  );
};
