import {isAxiosError} from 'axios';

import {User} from '@/features/user';

import api, {ApiResponse} from '@/lib/axios';
import storage from '@/utils/storage';

export const fetchCurrentUser = async (): Promise<User | null> => {
  try {
    if (!storage.getToken()) {
      return null;
    }

    const response = await api.get<ApiResponse<User>>('/user-profile');

    return response.data.data;
  } catch (e) {
    if (isAxiosError(e) && e.response?.status === 401) return null;

    throw e;
  }
};
