import {Box} from '@mui/material';
import * as React from 'react';

const RoleBadge = () => (
  <Box
    sx={{
      borderRadius: '64px',
      color: 'primary.600',
      backgroundColor: 'white',
      textAlign: 'center',
      fontWeight: '600',
      width: 'max-content',
      p: 1,
    }}
  >
    Petani Taniyuk
  </Box>
);

export default RoleBadge;
