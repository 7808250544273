import {Stack, Typography, useTheme} from '@mui/material';
import { Link, navigate } from "gatsby";
import React from 'react';

import {useSnackbarStore} from '@/stores/useSnackbarStore';

import imgMandor from '../../assets/images/mandor.png';
import imgPetani from '../../assets/images/petani.png';
import {RoleCard} from '../../components/RoleCard';
import {useRegisterStore} from '../../stores/useRegisterStore';

export const RoleSelectForm = () => {
  const theme = useTheme();

  const {showSnackbar} = useSnackbarStore();

  const {setData} = useRegisterStore();

  return (
    <>
      <Typography fontSize={20} fontWeight="bold" textAlign={'center'}>
        Bergabung dengan{' '}
        <span style={{color: theme.palette.primary.main}}>Taniyuk</span>
      </Typography>
      <Typography
        fontSize={14}
        textAlign={'center'}
        color={'#6B7280'}
        gutterBottom
      >
        Solusi untuk petani menyalurkan hasil panen ke pembeli dengan mudah dan
        terjamin.
      </Typography>
      <Typography fontSize={14}>Saya ingin bergabung sebagai?</Typography>
      <Stack paddingY={2} gap={3}>
        <RoleCard
          roleName={'Petani'}
          img={imgPetani}
          description={
            'Saya adalah petani atau produsen hasil tani yang siap bergabung dengan Taniyuk'
          }
          onClick={() => {
            setData({role: '1'});
            navigate('?step=account');
          }}
        />
        <RoleCard
          roleName={'Mandor'}
          img={imgMandor}
          description={
            'Saya adalah pengawas para petani yang siap bergabung dengan Taniyuk'
          }
          onClick={() => {
            showSnackbar({
              message: 'Fitur ini belum tersedia',
              severity: 'info',
              duration: 5000,
            });
          }}
        />
      </Stack>
      <Typography
        color="black"
        fontSize={14}
        fontWeight={600}
        textAlign="center"
      >
        Sudah punya akun?&nbsp;
        <Link
          to="/login"
          activeStyle={{color: '#03b579', textDecoration: 'none'}}
        >
          Masuk Sekarang
        </Link>
      </Typography>
    </>
  );
};
