export const generateFormData = (data: object) => {
  const formData = new FormData();

  for (const [key, value] of Object.entries(data)) {
    if (typeof value === 'string') {
      formData.append(key, value);
    } else if (typeof value === 'number') {
      formData.append(key, value.toString(10));
    } else if (value instanceof File) {
      formData.append(key, value);
    } else if (value === null) {
      formData.append(key, '');
    }
  }

  return formData;
};
