import {zodResolver} from '@hookform/resolvers/zod';
import {Close} from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import React from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import * as z from 'zod';

import {TextHookField} from '@/components/Form';

const schema = z.object({
  token: z
    .string()
    .regex(/^\d+$/, 'Token hanya boleh berisikan angka')
    .length(6, 'Token harus 6 karakter'),
});

type FATokenSchema = z.infer<typeof schema>;

export const FATokenDialog = ({
  open,
  onClose,
  onConfirm,
}: {
  open: boolean;
  onClose: () => void;
  onConfirm: (data: {token: string}) => void;
}) => {
  const {
    control,
    handleSubmit,
    resetField,
    formState: {isValid},
  } = useForm<FATokenSchema>({
    resolver: zodResolver(schema),
    defaultValues: {
      token: '',
    },
  });

  const onValid: SubmitHandler<FATokenSchema> = async (data) => {
    onConfirm(data);
  };

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
          resetField('token');
        }
      }}
      maxWidth={'tw_md'}
      fullWidth
    >
      <DialogTitle>
        Konfirmasi Token FA
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography>
          Masukan token sebagai tanda bahwa Anda menyatakan bahwa data sudah
          divalidasi.
        </Typography>
        <TextHookField
          name={'token'}
          label={'Token'}
          control={control}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button sx={{textTransform: 'none'}} onClick={onClose}>
          Batal
        </Button>
        <Button
          disabled={!isValid}
          variant={'contained'}
          onClick={handleSubmit(onValid)}
          sx={{textTransform: 'none'}}
        >
          Konfirmasi
        </Button>
      </DialogActions>
    </Dialog>
  );
};
