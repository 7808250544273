import {zodResolver} from '@hookform/resolvers/zod';
import {Close} from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import React from 'react';
import {useForm} from 'react-hook-form';
import * as z from 'zod';

import {FarmerTypesHookField} from '@/features/replanting';

const schema = z.object({
  farmer_type: z.object(
    {
      id: z.string(),
      name: z.string(),
    },
    {
      required_error: 'Jenis Petani harus diisi',
    },
  ),
});

type EditFarmerTypeSchema = z.infer<typeof schema>;

export const EditFarmerTypeDialog = ({
  open,
  onClose,
  onConfirm,
  values,
}: {
  open: boolean;
  onClose: () => void;
  onConfirm: (data: EditFarmerTypeSchema) => void;
  values: EditFarmerTypeSchema;
}) => {
  const {
    control,
    handleSubmit,
    formState: {isValid},
  } = useForm<EditFarmerTypeSchema>({
    resolver: zodResolver(schema),
    defaultValues: {
      farmer_type: values?.farmer_type,
    },
  });

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={'tw_md'}>
      <DialogTitle>
        Jenis Petani
        <IconButton
          aria-label="close"
          onClick={() => {
            onClose();
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FarmerTypesHookField
          name={'farmer_type'}
          control={control}
          label={'Petani'}
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!isValid}
          variant={'contained'}
          color={'primary'}
          onClick={handleSubmit((data) => {
            onConfirm(data);
          })}
        >
          Simpan
        </Button>
      </DialogActions>
    </Dialog>
  );
};
