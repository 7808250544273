import {DevTool} from '@hookform/devtools';
import {zodResolver} from '@hookform/resolvers/zod';
import {LoadingButton} from '@mui/lab';
import {Button, Stack} from '@mui/material';
import {navigate} from 'gatsby';
import * as React from 'react';
import {useEffect} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';

import {ProfileSchema, profileSchema} from '@/features/auth';
import {
  CityAutocompleteField,
  DistrictAutocompleteField,
  ProvinceAutocompleteField,
  RegencyAutocompleteField,
} from '@/features/regions';
import {checkUniqueData} from '@/features/user';

import {
  DateHookField,
  PatternHookField,
  TextHookField,
} from '@/components/Form';

import {useRegisterStore} from '../../stores/useRegisterStore';

export const ProfileForm = () => {
  const {setData, formData} = useRegisterStore();

  const {
    formState: {isValid, isSubmitting},
    control,
    watch,
    setError,
    setValue,
    resetField,
    handleSubmit,
    getFieldState,
  } = useForm<ProfileSchema>({
    mode: 'onBlur',
    resolver: zodResolver(profileSchema),
    defaultValues: {
      nik: formData.nik,
      birth_date: formData.birth_date,
      province_id: formData.province_id,
      city_id: formData.city_id,
      regency_id: formData.regency_id,
      district_id: formData.district_id,
      address: formData.address,
      npwp: formData.npwp,
      npwp_address: formData.npwp_address,
    },
  });

  const [selectedProvince, selectedCity, selectedRegency] = watch([
    'province_id',
    'city_id',
    'regency_id',
  ]);

  useEffect(() => {
    if (getFieldState('province_id').isDirty || !selectedProvince) {
      resetField('city_id');
    }
  }, [selectedProvince, setValue, getFieldState]);

  useEffect(() => {
    if (getFieldState('city_id').isDirty || !selectedCity) {
      resetField('regency_id');
    }
  }, [selectedCity, setValue, getFieldState]);

  useEffect(() => {
    if (getFieldState('regency_id').isDirty || !selectedRegency) {
      resetField('district_id');
    }
  }, [selectedRegency, setValue, getFieldState]);

  const submitHandler: SubmitHandler<ProfileSchema> = async (data) => {
    if (!data.nik && !data.npwp) {
      setData(data);
      navigate('?step=region');
    } else {
      const response = await checkUniqueData({
        nik: data.nik ?? undefined,
        npwp: data.npwp ?? undefined,
      });

      if (Object.entries(response).length === 0) {
        setData(data);
        navigate('?step=region');
      } else {
        Object.entries(response).forEach(([key, value]) => {
          setError(key as 'nik' | 'npwp', {
            type: 'server',
            message: value,
          });
        });
      }
    }
  };

  const prevButtonHandler = () => {
    navigate('?step=account');
  };

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <Stack alignItems={'stretch'} spacing={2}>
        <TextHookField
          name={'nik'}
          label={'Nomor Induk Kependudukan (NIK)'}
          control={control}
          fullWidth
          inputProps={{
            maxLength: 16,
            inputMode: 'numeric',
            pattern: '[0-9]*',
          }}
        />
        <PatternHookField
          name={'npwp'}
          label={'Nomor Pokok Wajib Pajak (NPWP)'}
          control={control}
          format={'##.###.###.#-###.###'}
          mask={'_'}
          fullWidth
        />
        <TextHookField
          name={'npwp_address'}
          label={'Alamat NPWP'}
          control={control}
          autoComplete={'street-address'}
          fullWidth
          multiline
          minRows={3}
        />
        <DateHookField
          name={'birth_date'}
          label={'Tanggal Lahir'}
          control={control}
          slotProps={{
            textField: {
              autoComplete: 'bday',
              size: 'medium',
            },
          }}
        />
        <ProvinceAutocompleteField
          name={'province_id'}
          label={'Provinsi'}
          control={control}
          fullWidth
        />
        <CityAutocompleteField
          name={'city_id'}
          label={'Kota/Kabupaten'}
          provinceId={selectedProvince?.id}
          control={control}
          fullWidth
        />
        <RegencyAutocompleteField
          name={'regency_id'}
          label={'Kecamatan'}
          cityId={selectedCity?.id}
          control={control}
          fullWidth
        />
        <DistrictAutocompleteField
          name={'district_id'}
          label={'Kelurahan/Desa'}
          regencyId={selectedRegency?.id}
          control={control}
          fullWidth
        />
        <TextHookField
          fullWidth
          name={'address'}
          autoComplete={'street-address'}
          label={'Alamat'}
          control={control}
          multiline
          minRows={3}
        />
        <LoadingButton
          sx={{marginY: 1, textTransform: 'none'}}
          color={'primary'}
          variant={'contained'}
          loading={isSubmitting}
          disabled={!isValid}
          fullWidth
          type={'submit'}
        >
          Selanjutnya
        </LoadingButton>
        <Button sx={{textTransform: 'none'}} onClick={prevButtonHandler}>
          Kembali
        </Button>
      </Stack>
      <DevTool control={control} />
    </form>
  );
};
