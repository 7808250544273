import {useQuery} from '@tanstack/react-query';

import {TransactionRegion} from '@/features/regions';

import axios, {ApiResponse} from '@/lib/axios';
import {QueryConfig} from '@/lib/react-query';


const getTransactionRegions = async (
  regionId: string,
): Promise<TransactionRegion[]> => {
  const response = await axios.get<ApiResponse<TransactionRegion[]>>(
    `/sub-regions/${regionId}`,
  );

  return response.data.data;
};

type UseTransactionRegionsOptions = {
  regionId?: string;
  config?: QueryConfig<typeof getTransactionRegions>;
};

export const useTransactionRegions = ({
  regionId,
  config,
}: UseTransactionRegionsOptions) => {
  return useQuery({
    queryKey: ['transaction-regions', regionId],
    queryFn: () => {
      return regionId ? getTransactionRegions(regionId) : [];
    },
    initialData: [],
    ...config,
  });
};
