import {useMutation, useQueryClient} from '@tanstack/react-query';
import {AxiosError} from 'axios';

import api from '@/lib/axios';
import {MutationConfig} from '@/lib/react-query';
import {generateFormData} from '@/utils/form-data';

type UpdateProfileDTO = {
  address: string | null;
  ancak_amount: number;
  birth_date: string | null;
  clone_type_id: string;
  district_id: string;
  drs_ended_at: string | null;
  drs_started_at: string | null;
  farmer_type: string;
  ktp?: Blob | null;
  land_area: number;
  latex_station_id: string;
  name: string;
  nik: string | null;
  npwp: string | null;
  npwp_address: string | null;
  password: string;
  phone: string;
  planting_year: string;
  principal_amount: number;
  skpl?: Blob | null;
  skt?: Blob | null;
  token: string;
};

const updateProfile = async (data: Partial<UpdateProfileDTO>) => {
  try {
    const formData = generateFormData(data);

    const response = await api.post('/user-profile', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  } catch (e) {
    if (e instanceof AxiosError) {
      throw e.response?.data ?? e.message;
    } else {
      throw e;
    }
  }
};

type UseUpdateProfileOptions = {
  config?: MutationConfig<typeof updateProfile>;
};

export const useUpdateProfile = ({config}: UseUpdateProfileOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['update-profile'],
    mutationFn: updateProfile,
    onSuccess: (data) => {
      queryClient.setQueryData(['current-user'], data.data);
    },
    ...config,
  });
};
