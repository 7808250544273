exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-bokar-tsx": () => import("./../../../src/pages/bokar.tsx" /* webpackChunkName: "component---src-pages-bokar-tsx" */),
  "component---src-pages-credit-[id]-tsx": () => import("./../../../src/pages/credit/[id].tsx" /* webpackChunkName: "component---src-pages-credit-[id]-tsx" */),
  "component---src-pages-credit-history-[id]-tsx": () => import("./../../../src/pages/credit/history/[id].tsx" /* webpackChunkName: "component---src-pages-credit-history-[id]-tsx" */),
  "component---src-pages-credit-history-index-tsx": () => import("./../../../src/pages/credit/history/index.tsx" /* webpackChunkName: "component---src-pages-credit-history-index-tsx" */),
  "component---src-pages-credit-index-tsx": () => import("./../../../src/pages/credit/index.tsx" /* webpackChunkName: "component---src-pages-credit-index-tsx" */),
  "component---src-pages-credit-products-[id]-tsx": () => import("./../../../src/pages/credit/products/[id].tsx" /* webpackChunkName: "component---src-pages-credit-products-[id]-tsx" */),
  "component---src-pages-credit-products-confirm-tsx": () => import("./../../../src/pages/credit/products/confirm.tsx" /* webpackChunkName: "component---src-pages-credit-products-confirm-tsx" */),
  "component---src-pages-credit-products-index-tsx": () => import("./../../../src/pages/credit/products/index.tsx" /* webpackChunkName: "component---src-pages-credit-products-index-tsx" */),
  "component---src-pages-home-tsx": () => import("./../../../src/pages/home.tsx" /* webpackChunkName: "component---src-pages-home-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-latex-premium-tsx": () => import("./../../../src/pages/latex-premium.tsx" /* webpackChunkName: "component---src-pages-latex-premium-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-loyalty-help-tsx": () => import("./../../../src/pages/loyalty/help.tsx" /* webpackChunkName: "component---src-pages-loyalty-help-tsx" */),
  "component---src-pages-loyalty-history-tsx": () => import("./../../../src/pages/loyalty/history.tsx" /* webpackChunkName: "component---src-pages-loyalty-history-tsx" */),
  "component---src-pages-loyalty-index-tsx": () => import("./../../../src/pages/loyalty/index.tsx" /* webpackChunkName: "component---src-pages-loyalty-index-tsx" */),
  "component---src-pages-loyalty-rewards-[id]-tsx": () => import("./../../../src/pages/loyalty/rewards/[id].tsx" /* webpackChunkName: "component---src-pages-loyalty-rewards-[id]-tsx" */),
  "component---src-pages-loyalty-rewards-index-tsx": () => import("./../../../src/pages/loyalty/rewards/index.tsx" /* webpackChunkName: "component---src-pages-loyalty-rewards-index-tsx" */),
  "component---src-pages-loyalty-success-tsx": () => import("./../../../src/pages/loyalty/success.tsx" /* webpackChunkName: "component---src-pages-loyalty-success-tsx" */),
  "component---src-pages-password-reset-tsx": () => import("./../../../src/pages/password-reset.tsx" /* webpackChunkName: "component---src-pages-password-reset-tsx" */),
  "component---src-pages-profile-edit-geolocation-[id]-tsx": () => import("./../../../src/pages/profile/edit/geolocation/[id].tsx" /* webpackChunkName: "component---src-pages-profile-edit-geolocation-[id]-tsx" */),
  "component---src-pages-profile-edit-geolocation-add-land-tsx": () => import("./../../../src/pages/profile/edit/geolocation/add-land.tsx" /* webpackChunkName: "component---src-pages-profile-edit-geolocation-add-land-tsx" */),
  "component---src-pages-profile-edit-geolocation-index-tsx": () => import("./../../../src/pages/profile/edit/geolocation/index.tsx" /* webpackChunkName: "component---src-pages-profile-edit-geolocation-index-tsx" */),
  "component---src-pages-profile-edit-identity-index-tsx": () => import("./../../../src/pages/profile/edit/identity/index.tsx" /* webpackChunkName: "component---src-pages-profile-edit-identity-index-tsx" */),
  "component---src-pages-profile-edit-region-tsx": () => import("./../../../src/pages/profile/edit/region.tsx" /* webpackChunkName: "component---src-pages-profile-edit-region-tsx" */),
  "component---src-pages-profile-index-tsx": () => import("./../../../src/pages/profile/index.tsx" /* webpackChunkName: "component---src-pages-profile-index-tsx" */),
  "component---src-pages-register-index-tsx": () => import("./../../../src/pages/register/index.tsx" /* webpackChunkName: "component---src-pages-register-index-tsx" */),
  "component---src-pages-transactions-[id]-tsx": () => import("./../../../src/pages/transactions/[id].tsx" /* webpackChunkName: "component---src-pages-transactions-[id]-tsx" */),
  "component---src-pages-transactions-index-tsx": () => import("./../../../src/pages/transactions/index.tsx" /* webpackChunkName: "component---src-pages-transactions-index-tsx" */),
  "component---src-pages-welcome-tsx": () => import("./../../../src/pages/welcome.tsx" /* webpackChunkName: "component---src-pages-welcome-tsx" */)
}

