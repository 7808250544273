import {
  AccountCircle,
  CheckCircleOutlined,
  DangerousOutlined,
  FmdGood,
  Info,
  Map,
  NavigateNext,
} from '@mui/icons-material';
import {
  Avatar,
  Divider,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Stack,
  Typography,
} from '@mui/material';
import {Link} from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import {User} from '@/features/user';

import {RoundedLoadingButton} from '@/components/Button/RoundedLoadingButton';
import {HomeLayout} from '@/components/Layout/Home/HomeLayout';

import profileHeaderBg from '@/assets/images/profile-header-background.png';
import {useAuth} from '@/lib/auth';

const ProfileHeader = ({user}: {user?: User | null}) => (
  <Grid
    container
    gap={1}
    flexWrap={'nowrap'}
    sx={{
      backgroundColor: 'primary.main',
      backgroundImage: `url(${profileHeaderBg})`,
      backgroundSize: 'contain',
      color: 'white',
      padding: '40px 20px',
    }}
  >
    <Grid item xs={3}>
      <Avatar
        sx={{
          height: 'clamp(20px,20vw,80px)',
          width: 'clamp(20px,20vw,80px)',
          margin: 'auto',
        }}
      />
    </Grid>
    <Grid item xs={9}>
      <Stack spacing={0.5}>
        <Typography fontWeight={600} fontSize={24}>
          {user?.profile_data?.name}
        </Typography>
        <Typography variant={'body1'}>{user?.profile_data?.phone}</Typography>
      </Stack>
    </Grid>
  </Grid>
);

ProfileHeader.propTypes = {
  user: PropTypes.object,
};

export const ProfilePage = () => {
  const {user, logout} = useAuth();

  const logoutHandler = async () => {
    await logout();
  };

  const getVerificationStatusText = (info?: {
    status: number;
    description: string;
  }) => {
    if (!info) return '';

    const statusIcon = {
      0: (
        <Info
          color={'error'}
          sx={{transform: 'rotate(180deg)'}}
          fontSize={'small'}
        />
      ),
      1: <DangerousOutlined color={'warning'} fontSize={'small'} />,
      2: <CheckCircleOutlined color={'primary'} fontSize={'small'} />,
    }[info.status];

    return (
      <>
        {statusIcon}
        <Typography fontSize={14} fontWeight={500}>
          {info.description}
        </Typography>
      </>
    );
  };

  return (
    <HomeLayout>
      <ProfileHeader user={user} />
      <List>
        <ListSubheader color="primary">Informasi Akun</ListSubheader>
        <ListItemButton component={Link} to={'/profile/edit/identity'}>
          <ListItemIcon sx={{minWidth: 36}}>
            <AccountCircle color={'primary'} />
          </ListItemIcon>
          <ListItemText>Data Diri</ListItemText>
          {getVerificationStatusText(user?.account_information?.data_profile)}
          <NavigateNext />
        </ListItemButton>
        <Divider />
        <ListItemButton component={Link} to={'/profile/edit/region'}>
          <ListItemIcon sx={{minWidth: 36}}>
            <Map color={'primary'} />
          </ListItemIcon>
          <ListItemText>Data Region</ListItemText>
          {getVerificationStatusText(user?.account_information?.data_region)}
          <NavigateNext />
        </ListItemButton>
        <ListItemButton component={Link} to={'/profile/edit/geolocation'}>
          <ListItemIcon sx={{minWidth: 36}}>
            <FmdGood color={'primary'} />
          </ListItemIcon>
          <ListItemText>Data Lokasi</ListItemText>
          {getVerificationStatusText(user?.account_information?.data_geolocation)}
          <NavigateNext />
        </ListItemButton>
        {/*<Divider/>*/}
        {/*<ListItemButton>*/}
        {/*  <ListItemIcon><VpnKeyOutlined/></ListItemIcon>*/}
        {/*  <ListItemText primary={'Ubah Password'}/>*/}
        {/*  <NavigateNext/>*/}
        {/*</ListItemButton>*/}
        {/*<Divider/>*/}
        {/*<ListItemButton>*/}
        {/*  <ListItemIcon><VpnKeyOutlined/></ListItemIcon>*/}
        {/*  <ListItemText primary={'Kode Mandor'}/>*/}
        {/*  <Typography variant={'caption'}>*/}
        {/*    {profile.supervisor_code || '-'}*/}
        {/*  </Typography>*/}
        {/*  <NavigateNext/>*/}
        {/*</ListItemButton>*/}
      </List>
      <RoundedLoadingButton
        fullWidth
        variant="outlined"
        onClick={logoutHandler}
        sx={{mt: 4}}
      >
        Keluar
      </RoundedLoadingButton>
    </HomeLayout>
  );
};
