import {DatePicker, DatePickerProps} from '@mui/x-date-pickers';
import * as React from 'react';
import {Control, FieldValues, Path, useController} from 'react-hook-form';

type DateHookFieldProps<T extends FieldValues> = {
  name: Path<T>;
  control: Control<T>;
  label: string;
} & DatePickerProps<unknown>;

export const DateHookField = <T extends FieldValues>({
  control,
  name,
  label,
  ...rest
}: DateHookFieldProps<T>) => {
  const {
    field: {onChange, onBlur, value, ref},
    fieldState: {error},
  } = useController({
    name,
    control,
  });

  return (
    <DatePicker
      label={label}
      value={value}
      inputRef={ref}
      onChange={onChange}
      slotProps={{
        textField: {
          name: name,
          label: label,
          margin: 'dense',
          onBlur: onBlur,
          error: !!error,
          fullWidth: true,
          helperText: error?.message ?? '',
          ...rest?.slotProps?.textField,
        },
      }}
      {...rest}
    />
  );
};
