import {Autocomplete, AutocompleteProps} from '@mui/material';
import * as React from 'react';
import {Control, Controller, FieldValues, Path} from 'react-hook-form';

export type AutocompleteHookFieldProps<
  TFieldValues extends FieldValues,
  TFieldPath extends Path<TFieldValues>,
  TValue extends object,
> = {
  control: Control<TFieldValues>;
  name: TFieldPath;
  disabled?: boolean;
  options?: TValue[];
} & AutocompleteProps<TValue, false, false, false>;

export const AutocompleteHookField = <
  TFieldValues extends FieldValues,
  TFieldPath extends Path<TFieldValues> = Path<TFieldValues>,
  TValue extends object = object,
>({
  name,
  control,
  disabled = false,
  ...rest
}: AutocompleteHookFieldProps<TFieldValues, TFieldPath, TValue>) => {
  return (
    <Controller
      name={name}
      control={control}
      disabled={disabled}
      render={({field}) => {
        return (
          <Autocomplete
            {...rest}
            {...field}
            onChange={(_, value) => field.onChange(value)}
          />
        );
      }}
    />
  );
};
