import {BaseTextFieldProps, TextField} from '@mui/material';
import * as React from 'react';
import {Control, Controller, FieldValues, Path} from 'react-hook-form';
import {PatternFormat, PatternFormatProps} from 'react-number-format';

export const PatternHookField = <TFieldValues extends FieldValues>({
  control,
  name,
  format,
  label,
  ...rest
}: {
  control: Control<TFieldValues>;
  name: Path<TFieldValues>;
  label: string;
  fullWidth?: boolean;
  margin?: 'dense' | 'normal';
} & PatternFormatProps<BaseTextFieldProps>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: {onChange, onBlur, value, ref},
        fieldState: {error},
      }) => {
        return (
          <PatternFormat
            value={value}
            onValueChange={(values) => {
              onChange(values.value);
            }}
            format={format}
            onBlur={onBlur}
            customInput={TextField}
            error={!!error}
            helperText={error?.message}
            getInputRef={ref}
            label={label}
            {...rest}
          />
        );
      }}
    />
  );
};

export default PatternHookField;
