import {zodResolver} from '@hookform/resolvers/zod';
import {Close} from '@mui/icons-material';
import {LoadingButton} from '@mui/lab';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import React from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import * as zod from 'zod';

import {checkUniqueData} from '@/features/user';

import PatternHookField from '@/components/Form/PatternHookField';


const schema = zod.object({
  npwp: zod
    .string()
    .nullable()
    .superRefine(async (value, ctx) => {
      if (!value) return true;

      const uniqueMessage = await checkUniqueData({
        npwp: value,
      });

      if (uniqueMessage.npwp !== undefined) {
        ctx.addIssue({
          code: zod.ZodIssueCode.custom,
          message: uniqueMessage.npwp,
          path: ['npwp'],
        });
      }
    }),
});

type EditNpwpFields = zod.infer<typeof schema>;

export const EditNpwpDialog = ({
  open,
  onClose,
  onConfirm,
  values,
}: {
  open: boolean;
  onClose: () => void;
  onConfirm: (values: EditNpwpFields) => void;
  values: Partial<EditNpwpFields>;
}) => {
  const {
    control,
    handleSubmit,
    formState: {isDirty, isSubmitting},
  } = useForm<EditNpwpFields>({
    resolver: zodResolver(schema),
    mode: 'onBlur',
    defaultValues: {
      npwp: values?.npwp,
    },
  });

  const submitCallback: SubmitHandler<EditNpwpFields> = async (data) => {
    onConfirm(data);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={'tw_md'}>
      <DialogTitle>
        NPWP
        <IconButton
          aria-label="close"
          onClick={() => {
            onClose();
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <PatternHookField
          name={'npwp'}
          label={'Nomor Pokok Wajib Pajak (NPWP)'}
          control={control}
          format={'##.###.###.#-###.###'}
          size={'small'}
          allowEmptyFormatting
          fullWidth
          margin={'normal'}
          mask={'_'}
        />
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={isSubmitting}
          variant={'contained'}
          disabled={!isDirty}
          color={'primary'}
          onClick={handleSubmit(submitCallback)}
        >
          Simpan
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
