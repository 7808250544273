import {useQuery} from '@tanstack/react-query';

import {Regency} from '@/features/regions';

import axios, {ApiResponse} from '@/lib/axios';
import {QueryConfig} from '@/lib/react-query';


// It should be Districts (Kecamatan) instead of Regencies (Kabupaten)
const fetchRegencies = async (cityId: string): Promise<Regency[]> => {
  const response = await axios.get<ApiResponse<Regency[]>>(
    `/address/regencies/${cityId}`,
  );

  return response.data.data;
};

type UseRegenciesOptions = {
  cityId?: string;
  config?: QueryConfig<typeof fetchRegencies>;
};

export const useRegencies = ({cityId, config}: UseRegenciesOptions) => {
  return useQuery({
    queryKey: ['regencies', cityId],
    queryFn: () => {
      return cityId ? fetchRegencies(cityId) : [];
    },
    initialData: [],
    ...config,
  });
};
