import {TextField} from '@mui/material';
import * as React from 'react';
import {Control, FieldValues, Path} from 'react-hook-form';

import {City, useCities} from '@/features/regions';

import {AutocompleteHookField} from '@/components/Form';


export const CityAutocompleteField = <TFieldValues extends FieldValues>({
  label,
  name,
  control,
  provinceId,
  ...rest
}: {
  label: string;
  name: Path<TFieldValues>;
  fullWidth?: boolean;
  provinceId?: string;
  control: Control<TFieldValues>;
}) => {
  const {data: cities, isFetching} = useCities({
    provinceId,
  });

  const isOptionEqualToValue = (option: City, value: City) => {
    return option.id === value.id;
  };

  const getOptionLabel = (option: City) => {
    return option.name;
  };

  return (
    <AutocompleteHookField
      {...rest}
      control={control}
      disabled={provinceId === undefined}
      getOptionLabel={getOptionLabel}
      loading={isFetching}
      isOptionEqualToValue={isOptionEqualToValue}
      name={name}
      options={cities ?? []}
      renderInput={(params) => {
        return <TextField label={label} {...params} />;
      }}
    />
  );
};
