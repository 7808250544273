import {LoadingButton} from '@mui/lab';
import {Avatar, Box, Divider, Stack, Typography} from '@mui/material';
import {navigate} from 'gatsby';
import {useModal} from 'mui-modal-provider';
import * as React from 'react';
import {useState} from 'react';
import {patternFormatter} from 'react-number-format';

import RoleBadge from '@/components/Badge/RoleBadge';
import {
  FlatAppBar,
  FlatAppBarBackButton,
  FlatAppBarLeft,
  FlatAppBarTitle,
} from '@/components/Layout/FlatAppBar';
import {Layout} from '@/components/Layout/Root';

import profileHeaderBg from '@/assets/images/profile-header-background.png';
import {useAuth} from '@/lib/auth';
import {useSnackbarStore} from '@/stores/useSnackbarStore';
import {dayjsOrElse} from '@/utils/dayjs';
import {handleError} from '@/utils/error';

import {useUpdateProfile} from '../api/updateProfile';
import {EditAddressDialog} from '../components/Dialog/EditAddressDialog';
import {EditDateOfBirthDialog} from '../components/Dialog/EditDateOfBirthDialog';
import {EditFarmerTypeDialog} from '../components/Dialog/EditFarmerTypeDialog';
import {EditNameDialog} from '../components/Dialog/EditNameDialog';
import {EditNikDialog} from '../components/Dialog/EditNikDialog';
import {EditNpwpAddressDialog} from '../components/Dialog/EditNpwpAddressDialog';
import {EditNpwpDialog} from '../components/Dialog/EditNpwpDialog';
import {EditPhoneDialog} from '../components/Dialog/EditPhoneDialog';
import {FATokenDialog} from '../components/Dialog/FATokenDialog';
import {UploadDocumentDialog} from '../components/Dialog/UploadDocumentDialog';
import {EditProfileItem} from '../components/EditProfileItem';
import {EditProfileData, editIdentitySchema} from '../stores/formdata';


export const EditIdentityPage = () => {
  const {user} = useAuth();

  const updateProfileMutation = useUpdateProfile();

  const {showModal} = useModal();

  const snackbarStore = useSnackbarStore();

  const [data, setData] = useState<EditProfileData>({
    name: user?.profile_data?.name ?? '',
    phone: user?.profile_data?.phone ?? '',
    birth_date:
      dayjsOrElse(user?.profile_data?.birth_date, 'YYYY-MM-DD') ?? null,
    nik: user?.profile_data?.nik ?? null,
    npwp: user?.profile_data?.npwp ?? null,
    npwp_address: user?.profile_data?.npwp_address ?? null,
    address: user?.address_data?.address ?? null,
    province: user?.address_data?.province ?? null,
    city: user?.address_data?.city ?? null,
    regency: user?.address_data?.regency ?? null,
    district: user?.address_data?.district ?? null,
    farmer_type: user?.profile_data?.farmer_type ?? null,
    ktp: user?.profile_data?.docs?.ktp_url,
    skt: user?.profile_data?.docs?.skt_url,
    skpl: user?.profile_data?.docs?.skpl_url,
  });

  const updateData = (data: Partial<EditProfileData>) => {
    setData((state) => ({
      ...state,
      ...data,
    }));
  };

  const getStatusText = (status: 0 | 1 | 2 = 0) => {
    switch (status) {
      case 2:
        return 'Telah divalidasi';
      case 1:
        return 'Menunggu verifikasi';
      case 0:
        return 'Lengkapi data';
    }
  };

  const updateProfile = async (
    data: EditProfileData & {
      token: string;
    },
  ) => {
    const parsedData = editIdentitySchema.parse(data);

    try {
      await updateProfileMutation.mutateAsync({
        name: parsedData.name,
        phone: parsedData.phone,
        birth_date: parsedData.birth_date,
        nik: parsedData.nik,
        npwp: parsedData.npwp,
        npwp_address: parsedData.npwp_address,
        address: parsedData.address,
        district_id: parsedData.district,
        farmer_type: parsedData.farmer_type,
        ktp: parsedData.ktp,
        skt: parsedData.skt,
        skpl: parsedData.skpl,
        token: data.token,
      });

      snackbarStore.showSnackbar({
        message: 'Data Diri berhasil diubah',
        severity: 'success',
        duration: 100,
      });

      await navigate('/profile', {replace: true});
    } catch (e) {
      const error = handleError(e);

      snackbarStore.showSnackbar({
        message: error.message,
        severity: 'error',
        duration: 100,
      });
    }
  };

  const nameClickHandler = () => {
    const modal = showModal(EditNameDialog, {
      onClose() {
        modal.destroy();
      },
      values: {
        name: data.name,
      },
      onConfirm: (data) => {
        updateData(data);
        modal.destroy();
      },
    });
  };

  const phoneClickHandler = () => {
    const modal = showModal(EditPhoneDialog, {
      values: {
        phone: data.phone,
      },
      onClose() {
        modal.destroy();
      },
      onConfirm: (data) => {
        updateData(data);
        modal.destroy();
      },
    });
  };
  const nikClickHandler = () => {
    const modal = showModal(EditNikDialog, {
      values: {
        nik: data.nik,
      },
      onClose() {
        modal.destroy();
      },
      onConfirm: (data) => {
        updateData(data);
        modal.destroy();
      },
    });
  };
  const dobClickHandler = () => {
    const modal = showModal(EditDateOfBirthDialog, {
      values: {
        birth_date: data.birth_date,
      },
      onClose() {
        modal.destroy();
      },
      onConfirm: (data) => {
        updateData(data);
        modal.destroy();
      },
    });
  };

  const npwpClickHandler = () => {
    const modal = showModal(EditNpwpDialog, {
      values: {
        npwp: data.npwp,
      },
      onClose() {
        modal.destroy();
      },
      onConfirm: (data) => {
        updateData(data);
        modal.destroy();
      },
    });
  };

  const npwpAddressClickHandler = () => {
    const modal = showModal(EditNpwpAddressDialog, {
      values: {
        npwp_address: data.npwp_address,
      },
      onClose() {
        modal.destroy();
      },
      onConfirm: (data) => {
        updateData(data);
        modal.destroy();
      },
    });
  };

  const addressClickHandler = () => {
    const modal = showModal(EditAddressDialog, {
      values: {
        address: data.address,
        city: data.city,
        district: data.district,
        province: data.province,
        regency: data.regency,
      },
      onClose() {
        modal.destroy();
      },
      onConfirm: (data) => {
        updateData(data);
        modal.destroy();
      },
    });
  };

  const farmerClickHandler = () => {
    const modal = showModal(EditFarmerTypeDialog, {
      values: {
        farmer_type: data.farmer_type!,
      },
      onClose() {
        modal.destroy();
      },
      onConfirm: (data) => {
        updateData(data);
        modal.destroy();
      },
    });
  };

  const documentClickHandler = () => {
    const modal = showModal(UploadDocumentDialog, {
      values: {
        ktp: data.ktp,
        skt: data.skt,
        skpl: data.skpl,
      },
      onClose() {
        modal.destroy();
      },
      onConfirm: (data) => {
        updateData(data);
        modal.destroy();
      },
    });
  };

  const faTokenHandler = () => {
    const modal = showModal(FATokenDialog, {
      onClose() {
        modal.destroy();
      },
      onConfirm: async ({token}) => {
        await updateProfile({
          ...data,
          token,
        });
        modal.destroy();
      },
    });
  };

  return (
    <Layout>
      <FlatAppBar>
        <FlatAppBarLeft>
          <FlatAppBarBackButton onClick={() => navigate('/profile')} />
        </FlatAppBarLeft>
        <FlatAppBarTitle sx={{position: 'absolute'}}>
          Edit Data Diri
        </FlatAppBarTitle>
      </FlatAppBar>
      <Box
        py={2}
        sx={{
          backgroundColor: '#38b548',
          backgroundImage: `url(${profileHeaderBg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <Stack spacing={0.5} alignItems={'center'} justifyContent={'center'}>
          <Avatar
            sx={{
              height: '80px',
              width: '80px',
            }}
          />
          <Typography color={'white'} fontWeight={600}>
            {user?.profile_data?.name}
          </Typography>
          <RoleBadge />
        </Stack>
      </Box>
      <Stack divider={<Divider />}>
        <EditProfileItem
          title={'Nama Lengkap'}
          value={data?.name}
          onClick={nameClickHandler}
        />
        <EditProfileItem
          title={'Nomor Telepon'}
          value={data?.phone}
          onClick={phoneClickHandler}
        />
        <EditProfileItem
          title={'Nomor Induk Kependudukan'}
          value={data?.nik}
          disabled={user?.profile_data?.status === 2}
          onClick={nikClickHandler}
        />
        <EditProfileItem
          title={'Nomor Pokok Wajib Pajak (NPWP)'}
          value={patternFormatter(data?.npwp ?? '', {
            format: '##.###.###.#-###.###',
          })}
          onClick={npwpClickHandler}
        />
        <EditProfileItem
          title={'Alamat NPWP'}
          value={data?.npwp_address}
          onClick={npwpAddressClickHandler}
        />
        <EditProfileItem
          title={'Tanggal Lahir'}
          value={data?.birth_date?.format('DD MMMM YYYY')}
          placeholder={'Atur sekarang'}
          onClick={dobClickHandler}
        />
        <EditProfileItem
          title={'Alamat'}
          value={data?.address}
          onClick={addressClickHandler}
        />
        <EditProfileItem
          title={'Kelurahan'}
          value={data?.district?.name}
          placeholder={'Belum diatur'}
          readonly
        />
        <EditProfileItem
          title={'Kecamatan'}
          value={data?.regency?.name}
          placeholder={'Belum diatur'}
          readonly
        />
        <EditProfileItem
          title={'Kabupaten/Kota'}
          value={data?.city?.name}
          placeholder={'Belum diatur'}
          readonly
        />
        <EditProfileItem
          title={'Provinsi'}
          value={data?.province?.name}
          placeholder={'Belum diatur'}
          readonly
        />
        <EditProfileItem
          title={'Petani'}
          value={data?.farmer_type?.name}
          onClick={farmerClickHandler}
        />
        <EditProfileItem
          title={'Dokumen Penting'}
          value={getStatusText(user?.profile_data?.status)}
          readonly={user?.profile_data?.status === 2}
          onClick={documentClickHandler}
        />
      </Stack>
      <LoadingButton
        variant={'contained'}
        fullWidth
        loading={updateProfileMutation.isPending}
        onClick={() => {
          faTokenHandler();
        }}
        sx={{
          marginTop: 2,
        }}
      >
        Simpan
      </LoadingButton>
    </Layout>
  );
};
