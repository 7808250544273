import {DevTool} from '@hookform/devtools';
import {zodResolver} from '@hookform/resolvers/zod';
import {Button, Dialog, Stack, Typography} from '@mui/material';
import * as React from 'react';
import {useForm} from 'react-hook-form';
import * as z from 'zod';

import {ImageUploadCard} from '@/components/Card/ImageUploadCard';
import {
  FlatAppBar,
  FlatAppBarBackButton,
  FlatAppBarLeft,
  FlatAppBarTitle,
} from '@/components/Layout/FlatAppBar';

const schema = z.object({
  ktp: z.union([z.instanceof(Blob), z.string().url()]).nullable(),
  skt: z.union([z.instanceof(Blob), z.string().url()]).nullable(),
  skpl: z.union([z.instanceof(Blob), z.string().url()]).nullable(),
});

type DocumentFields = z.infer<typeof schema>;

export const UploadDocumentDialog = ({
  open,
  onClose,
  onConfirm,
  values,
}: {
  open: boolean;
  onClose: () => void;
  onConfirm: (data: object) => void;
  values?: Partial<DocumentFields>;
}) => {
  const {
    control,
    formState: {isDirty},
    handleSubmit,
  } = useForm<DocumentFields>({
    resolver: zodResolver(schema),
    defaultValues: {
      ktp: values?.ktp,
      skt: values?.skt,
      skpl: values?.skpl,
    },
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen
      PaperProps={{
        sx: {
          maxWidth: '28rem',
          marginX: 'auto',
        },
      }}
    >
      <FlatAppBar>
        <FlatAppBarLeft>
          <FlatAppBarBackButton
            onClick={() => {
              onClose();
            }}
          />
        </FlatAppBarLeft>
        <FlatAppBarTitle>Unggah Dokumen</FlatAppBarTitle>
      </FlatAppBar>
      <Stack gap={1} margin={2}>
        <Typography fontSize={14} fontWeight={500} gutterBottom>
          Kartu Tanda Penduduk (KTP)
        </Typography>
        <ImageUploadCard name={'ktp'} control={control} />
        <Typography fontSize={14} fontWeight={500} gutterBottom>
          Surat Keterangan Tanah (SKT)
        </Typography>
        <ImageUploadCard name={'skt'} control={control} />
        <Typography fontSize={14} fontWeight={500} gutterBottom>
          Surat Keterangan Penggunaan Lahan (SKPL)
        </Typography>
        <ImageUploadCard name={'skpl'} control={control} />
        <Button
          variant={'contained'}
          disabled={!isDirty}
          fullWidth
          onClick={handleSubmit((data) => {
            onConfirm(data);
          })}
        >
          Simpan
        </Button>

        <ol style={{listStyleType: 'decimal', paddingLeft: '20px'}}>
          <li>Foto dokumen harus berada dalam area foto.</li>
          <li>Pastikan foto dokumen tidak tertutup apapun.</li>
          <li>Foto dokumen harus terlihat jelas dan mudah dibaca.</li>
          <li>Dokumen harus asli, tidak dimodifikasi dalam bentuk apapun.</li>
        </ol>
      </Stack>
      <DevTool control={control} />
    </Dialog>
  );
};
