type OldToken = {
  token: string;
};

const storage = {
  getToken: () => {
    const parsedJson = JSON.parse(
      localStorage.getItem('token') as string,
    ) satisfies OldToken | string | null;

    //Backwards Compatibility for existing tokens
    if (typeof parsedJson === 'object') {
      return parsedJson?.token;
    }

    return parsedJson;
  },
  setToken: (token: string) => {
    localStorage.setItem('token', JSON.stringify(token));
  },
  clearToken: () => {
    localStorage.removeItem('token');
  },
};

export default storage;
