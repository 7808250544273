import {zodResolver} from '@hookform/resolvers/zod';
import {ExpandLess, ExpandMore} from '@mui/icons-material';
import {LoadingButton} from '@mui/lab';
import {
  Collapse,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import {navigate} from 'gatsby';
import React from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';

import {useDisclosure} from '@/hooks/useDisclosure';

import {ImageUploadCard} from '@/components/Card/ImageUploadCard';
import {TextHookField} from '@/components/Form';
import {DateHookField} from '@/components/Form/DateHookField';
import PatternHookField from '@/components/Form/PatternHookField';
import {
  FlatAppBar,
  FlatAppBarBackButton,
  FlatAppBarLeft,
  FlatAppBarTitle,
} from '@/components/Layout/FlatAppBar';
import {Layout} from '@/components/Layout/Root';

import {useAuth} from '@/lib/auth';
import dayjs from '@/lib/dayjs';
import {useSnackbarStore} from '@/stores/useSnackbarStore';

import {useUpdateProfile} from '../api/updateProfile';
import {
  EditProfileData,
  EditProfileOutput,
  editIdentitySchema,
} from '../stores/formdata';


export const FutureEditIdentityPage = () => {
  const {user} = useAuth();

  const accountSection = useDisclosure();
  const identitySection = useDisclosure();
  const taxSection = useDisclosure();
  const documentSection = useDisclosure();

  const updateProfileMutation = useUpdateProfile();

  const {
    control,
    formState: {isDirty, isValid},
    handleSubmit,
  } = useForm<EditProfileData, unknown, EditProfileOutput>({
    resolver: zodResolver(editIdentitySchema),
    defaultValues: {
      address: user?.address_data?.address,
      birth_date: dayjs(user?.profile_data?.birth_date, 'YYYY-MM-DD'),
      city: user?.address_data?.city,
      district: user?.address_data?.district,
      ktp: user?.profile_data?.docs?.ktp_url,
      name: user?.profile_data?.name,
      nik: user?.profile_data?.nik,
      npwp: user?.profile_data?.npwp,
      npwp_address: user?.profile_data?.npwp_address,
      phone: user?.profile_data?.phone,
      province: user?.address_data?.province,
      regency: user?.address_data?.regency,
      skpl: user?.profile_data?.docs?.skpl_url,
      skt: user?.profile_data?.docs?.skt_url,
      token: '',
    },
  });

  const snackbarStore = useSnackbarStore();

  const updateProfileHandler: SubmitHandler<EditProfileOutput> = async (
    data,
  ) => {
    await updateProfileMutation.mutateAsync({
      address: data.address,
      birth_date: data.birth_date,
      district_id: data.district,
      ktp: data.ktp,
      name: data.name,
      nik: data.nik,
      npwp: data.npwp,
      npwp_address: data.npwp_address,
      phone: data.phone,
      skpl: data.skpl,
      skt: data.skt,
      token: data.token,
    });

    await navigate('/profile', {replace: true});

    snackbarStore.showSnackbar({
      message: 'Data Diri berhasil diubah',
      severity: 'success',
      duration: 100,
    });
  };

  return (
    <Layout>
      <FlatAppBar>
        <FlatAppBarLeft>
          <FlatAppBarBackButton onClick={() => navigate('/profile')} />
        </FlatAppBarLeft>
        <FlatAppBarTitle sx={{position: 'absolute'}}>
          Edit Data Diri
        </FlatAppBarTitle>
      </FlatAppBar>
      <Stack>
        <ListItemButton
          onClick={() => {
            accountSection.toggle();
          }}
        >
          <ListItemText>Akun</ListItemText>
          {accountSection.isOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={accountSection.isOpen}>
          <TextHookField
            name={'name'}
            label={'Nama'}
            control={control}
            margin={'normal'}
            fullWidth
          />
          <TextHookField
            name={'phone'}
            label={'Nomor Telepon'}
            control={control}
            margin={'normal'}
            fullWidth
          />
        </Collapse>

        <ListItemButton
          onClick={() => {
            identitySection.toggle();
          }}
        >
          <ListItemText>Data Diri</ListItemText>
          {identitySection.isOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={identitySection.isOpen}>
          <DateHookField
            name={'birth_date'}
            label={'Tanggal Lahir'}
            control={control}
          />
          <TextHookField
            name={'nik'}
            label={'NIK'}
            control={control}
            margin={'normal'}
            fullWidth
          />
          <TextHookField
            name={'address'}
            label={'Alamat'}
            control={control}
            margin={'normal'}
            fullWidth
          />
        </Collapse>

        <ListItemButton
          onClick={() => {
            taxSection.toggle();
          }}
        >
          <ListItemText>Data Pajak</ListItemText>
          {taxSection.isOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={taxSection.isOpen}>
          <PatternHookField
            name={'npwp'}
            label={'Nomor Pokok Wajib Pajak (NPWP)'}
            control={control}
            format={'##.###.###.#-###.###'}
            allowEmptyFormatting
            fullWidth
            margin={'normal'}
            mask={'_'}
          />
          <TextHookField
            name={'npwp_address'}
            label={'Alamat NPWP'}
            control={control}
            fullWidth
            margin={'normal'}
            maxRows={4}
          />
        </Collapse>

        <ListItemButton
          onClick={() => {
            documentSection.toggle();
          }}
        >
          <ListItemText>Dokumen</ListItemText>
          {documentSection.isOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={documentSection.isOpen}>
          <Typography fontSize={14} fontWeight={500} gutterBottom>
            Foto KTP
          </Typography>
          <ImageUploadCard name={'ktp'} control={control} />
          <Typography fontSize={14} fontWeight={500} gutterBottom>
            Foto Surat Keterangan Tanah (SKT)
          </Typography>
          <ImageUploadCard name={'skt'} control={control} />
          <Typography fontSize={14} fontWeight={500} gutterBottom>
            Foto Surat Kuasa Penggunaan Lahan
          </Typography>
          <ImageUploadCard name={'skpl'} control={control} />
        </Collapse>

        <TextHookField
          name={'token'}
          label={'Token'}
          control={control}
          margin={'normal'}
          fullWidth
        />
        <LoadingButton
          loading={updateProfileMutation.isPending}
          disabled={!isDirty || !isValid}
          onClick={handleSubmit(updateProfileHandler)}
          variant={'contained'}
          fullWidth
        >
          Simpan
        </LoadingButton>
      </Stack>
    </Layout>
  );
};
