import {
  Checkbox,
  FormControlLabel,
  FormControlLabelProps,
  FormGroup,
} from '@mui/material';
import * as React from 'react';
import {Control, FieldValues, Path, useController} from 'react-hook-form';

type CheckboxHookFieldProps<T extends FieldValues> = {
  name: Path<T>;
  control: Control<T>;
  label: string;
} & Omit<FormControlLabelProps, 'name' | 'control'>;

export const CheckboxHookField = <T extends FieldValues>({
  control,
  label,
  name,
  ...rest
}: CheckboxHookFieldProps<T>) => {
  const {
    field: {value, onChange, onBlur},
  } = useController({
    name,
    control,
  });

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
          />
        }
        label={label}
        componentsProps={{typography: {fontSize: '10px'}}}
        {...rest}
      />
    </FormGroup>
  );
};
