import * as z from 'zod';

import dayjs from '@/lib/dayjs';

export const editIdentitySchema = z.object({
  name: z.string({
    required_error: 'Nama harus diisi',
  }),
  phone: z.string({
    required_error: 'Nomor Telepon harus diisi',
  }),
  birth_date: z
    .instanceof(dayjs as unknown as typeof dayjs.Dayjs)
    .nullable()
    .transform((val) => val?.format('YYYY-MM-DD')),
  nik: z.string().min(16, 'NIK harus 16 karakter').nullable(),
  npwp: z.string().nullable(),
  npwp_address: z.string().nullable(),
  address: z.string().nullable(),
  province: z
    .object({
      id: z.string(),
      name: z.string(),
    })
    .passthrough()
    .nullable()
    .transform((val) => val?.id),
  city: z
    .object({
      id: z.string(),
      name: z.string(),
    })
    .nullable()
    .transform((val) => val?.id),
  regency: z
    .object({
      id: z.string(),
      name: z.string(),
    })
    .nullable()
    .transform((val) => val?.id),
  district: z
    .object({
      id: z.string(),
      name: z.string(),
    })
    .nullable()
    .transform((val) => val?.id),
  farmer_type: z
    .object({id: z.string(), name: z.string()})
    .nullable()
    .brand('FarmerType')
    .refine((val) => val !== null, {
      message: 'Jenis Petani wajib diisi',
      path: ['farmer_type'],
    })
    .transform((val) => val.id),
  ktp: z
    .union([z.instanceof(Blob), z.string().url()])
    .nullish()
    .transform((val) => {
      if (typeof val === 'string') return undefined;

      return val;
    }),
  skt: z
    .union([z.instanceof(Blob), z.string().url()])
    .nullish()
    .transform((val) => {
      if (typeof val === 'string') return undefined;

      return val;
    }),
  skpl: z
    .union([z.instanceof(Blob), z.string().url()])
    .nullish()
    .transform((val) => {
      if (typeof val === 'string') return undefined;

      return val;
    }),
});

export type EditProfileData = z.input<typeof editIdentitySchema>;
export type EditProfileOutput = z.output<typeof editIdentitySchema>;
