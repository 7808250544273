import {Button, Stack, Typography} from '@mui/material';
import {navigate} from 'gatsby';
import * as React from 'react';

import {Layout} from '@/components/Layout/Root';
import {Image} from '@/components/UI/Image';

import {useAuth} from '@/lib/auth';

import welcomeImg from '../assets/images/welcome-img.png';
import {useRegisterStore} from '../stores/useRegisterStore';


export const WelcomePage = () => {
  const {user} = useAuth();
  const {reset} = useRegisterStore();

  const nextButtonHandler = async () => {
    reset();
    await navigate('/home', {
      replace: true,
    });
  };

  return (
    <Layout>
      <Stack gap={2} alignItems={'center'} textAlign={'center'}>
        <Image
          src={welcomeImg}
          alt={'Welcome Image'}
          sx={{
            width: '100%',
            height: 'auto',
            maxWidth: 300,
          }}
        />
        <Typography fontWeight={600}>
          Selamat bergabung,
          <br />
          <Typography
            component={'span'}
            variant={'h5'}
            fontWeight={700}
            color={'primary'}
          >
            {user?.profile_data?.name}
          </Typography>
        </Typography>
        <Typography>
          Anda sudah resmi menjadi
          <br />
          Petani Taniyuk
        </Typography>
        <Button
          variant={'contained'}
          disabled={!user}
          fullWidth
          onClick={nextButtonHandler}
          sx={{
            textTransform: 'none',
            fontWeight: 700,
          }}
        >
          Mulai Sekarang
        </Button>
      </Stack>
    </Layout>
  );
};
