import {TextField} from '@mui/material';
import * as React from 'react';
import {Control, FieldValues, Path} from 'react-hook-form';

import {TransactionRegion, useTransactionRegions} from '@/features/regions';

import {AutocompleteHookField} from '@/components/Form';

export const TransactionRegionAutocompleteField = <
  TFieldValues extends FieldValues,
>({
  label,
  name,
  control,
  regionId,
}: {
  label: string;
  regionId?: string;
  control: Control<TFieldValues>;
  name: Path<TFieldValues>;
  disabled?: boolean;
  fullWidth?: boolean;
}) => {
  const {data: subregions, isFetching} = useTransactionRegions({
    regionId,
    config: {
      enabled: regionId !== null,
    },
  });

  const optionLabel = (option: TransactionRegion) => option.name;

  const isOptionEqualToValue = (
    option: TransactionRegion,
    value: TransactionRegion,
  ) => {
    return option.id === value.id;
  };

  return (
    <AutocompleteHookField
      name={name}
      control={control}
      getOptionLabel={optionLabel}
      loading={isFetching}
      isOptionEqualToValue={isOptionEqualToValue}
      options={subregions ?? []}
      renderInput={(params) => {
        return <TextField label={label} {...params} />;
      }}
    />
  );
};
