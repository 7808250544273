import {NavigateNext} from '@mui/icons-material';
import {
  ListItemButton,
  ListItemButtonProps,
  ListItemText,
  Typography,
} from '@mui/material';
import * as React from 'react';

type EditProfileItemProps = {
  title: string;
  value?: string | null;
  placeholder?: string;
  disabled?: boolean;
  readonly?: boolean;
} & ListItemButtonProps;

export const EditProfileItem = ({
  disabled = false,
  placeholder,
  readonly,
  title,
  value,
  ...rest
}: EditProfileItemProps) => {
  return (
    <ListItemButton disabled={disabled || readonly} {...rest}>
      <ListItemText
        primaryTypographyProps={{
          color: '#03B579',
          fontWeight: 600,
          fontSize: '14px',
        }}
      >
        {title}
      </ListItemText>
      <Typography
        fontWeight={400}
        fontSize={14}
        maxWidth={'30%'}
        whiteSpace={'nowrap'}
        overflow={'hidden'}
        textAlign={'right'}
        textOverflow={'ellipsis'}
        color={value ? 'black' : 'rgba(0,0,0,0.5)'}
      >
        {value || placeholder || 'Atur sekarang'}
      </Typography>
      <NavigateNext sx={{color: readonly ? 'transparent' : 'black'}} />
    </ListItemButton>
  );
};
