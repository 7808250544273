import {useQuery} from '@tanstack/react-query';

import api from '@/lib/axios';
import {QueryConfig} from '@/lib/react-query';

const getCloneTypes = async () => {
  const response = await api.get('/clone-types');

  return response.data.data;
};

type UseCloneTypesOptions = {
  config?: QueryConfig<typeof getCloneTypes>;
};

export const useCloneTypes = ({config}: UseCloneTypesOptions = {}) => {
  return useQuery({
    queryKey: ['clone-types'],
    queryFn: getCloneTypes,
    initialData: [],
    ...config,
  });
};
