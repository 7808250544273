import {useQuery} from '@tanstack/react-query';

import axios from '@/lib/axios';
import {QueryConfig} from '@/lib/react-query';


// It should be Wards (Kelurahan) instead of Districts (Kecamatan)
const fetchDistricts = async (regencyId: string) => {
  const response = await axios.get(`/address/districts/${regencyId}`);

  return response.data.data;
};

type UseDistrictsOptions = {
  regencyId?: string;
  config?: QueryConfig<typeof fetchDistricts>;
};

export const useDistricts = ({regencyId, config}: UseDistrictsOptions) => {
  return useQuery({
    queryKey: ['districts', regencyId],
    queryFn: () => (regencyId ? fetchDistricts(regencyId) : []),
    initialData: [],
    ...config,
  });
};
