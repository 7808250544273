import {useQuery} from '@tanstack/react-query';

import {Region} from '@/features/regions';

import axios, {ApiResponse} from '@/lib/axios';
import {QueryConfig} from '@/lib/react-query';


const getRegions = async (): Promise<Region[]> => {
  const response = await axios.get<ApiResponse<Region[]>>('/regions');

  return response.data.data;
};

type UseRegionsOptions = {
  config?: QueryConfig<typeof getRegions>;
};

export const useRegions = ({config}: UseRegionsOptions = {}) => {
  return useQuery({
    queryKey: ['regions'],
    queryFn: () => getRegions(),
    initialData: [],
    ...config,
  });
};
