import {TextField} from '@mui/material';
import * as React from 'react';
import {Control, FieldValues, Path} from 'react-hook-form';

import {useLatexStations} from '@/features/regions';

import {AutocompleteHookField} from '@/components/Form';

export const LatexStationAutocompleteField = <
  TFieldValues extends FieldValues,
>({
    label,
    name,
    transactionRegionId,
    control,
    ...rest
  }: {
  label: string;
  transactionRegionId?: string;
  disabled?: boolean;
  control: Control<TFieldValues>;
  name: Path<TFieldValues>;
  fullWidth?: boolean;
}) => {
  const {data: stations, isFetching} = useLatexStations({
    transactionRegionId,
    config: {
      enabled: transactionRegionId !== null,
    },
  });

  return (
    <AutocompleteHookField
      {...rest}
      control={control}
      getOptionLabel={(option) => option.name}
      loading={isFetching}
      name={name}
      options={stations ?? []}
      isOptionEqualToValue={(option, value) => {
        return option.id === value.id;
      }}
      renderInput={(params) => {
        return <TextField label={label} {...params} />;
      }}
    />
  );
};
