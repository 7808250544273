import {DevTool} from '@hookform/devtools';
import {zodResolver} from '@hookform/resolvers/zod';
import {LoadingButton} from '@mui/lab';
import {Button, Stack, Unstable_Grid2} from '@mui/material';
import {navigate} from 'gatsby';
import * as React from 'react';
import {useEffect} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';

import {RegionSchema, regionSchema} from '@/features/auth';
import {
  LatexStationAutocompleteField,
  RegionAutocompleteField,
  TransactionRegionAutocompleteField,
} from '@/features/regions';
import {CloneTypesHookField} from '@/features/replanting';

import {NumericHookField} from '@/components/Form';
import {DateHookField} from '@/components/Form/DateHookField';
import {TimeHookField} from '@/components/Form/TimeHookField';

import {useRegisterStore} from '../../stores/useRegisterStore';


export const RegionForm = () => {
  const {setData, formData} = useRegisterStore();

  const {
    formState: {isValid},
    setValue,
    resetField,
    control,
    handleSubmit,
    watch,
    getFieldState,
  } = useForm<RegionSchema>({
    mode: 'onBlur',
    resolver: zodResolver(regionSchema),
    defaultValues: {
      region_id: formData.region_id,
      transaction_region_id: formData.transaction_region_id,
      latex_station_id: formData.latex_station_id,
      land_area: formData.land_area,
      ancak_amount: formData.ancak_amount,
      drs_started_at: formData.drs_started_at,
      drs_ended_at: formData.drs_ended_at,
      clone_type_id: formData.clone_type_id,
      planting_year: formData.planting_year,
      principal_amount: formData.principal_amount,
    },
  });

  const [selectedRegion, selectedTrxRegion] = watch([
    'region_id',
    'transaction_region_id',
  ]);

  useEffect(() => {
    if (getFieldState('region_id').isDirty || !selectedRegion) {
      resetField('transaction_region_id');
    }
  }, [selectedRegion, setValue]);

  useEffect(() => {
    if (getFieldState('transaction_region_id').isDirty || !selectedTrxRegion) {
      resetField('latex_station_id');
    }
  }, [selectedTrxRegion, setValue]);

  const onValidCallback: SubmitHandler<RegionSchema> = (data) => {
    setData(data);
    navigate('?step=document');
  };

  const prevButtonHandler = () => {
    navigate('?step=profile');
  };

  return (
    <form onSubmit={handleSubmit(onValidCallback)}>
      <Stack alignItems={'stretch'} spacing={2}>
        <RegionAutocompleteField
          name={'region_id'}
          label={'Daerah'}
          control={control}
          fullWidth
        />
        <TransactionRegionAutocompleteField
          name={'transaction_region_id'}
          label={'Subdaerah'}
          control={control}
          regionId={selectedRegion?.id}
          fullWidth
        />
        <LatexStationAutocompleteField
          name={'latex_station_id'}
          label={'Stasiun Latex'}
          control={control}
          transactionRegionId={selectedTrxRegion?.id}
          fullWidth
        />
        <NumericHookField
          fullWidth
          name={'land_area'}
          label={'Luas Lahan'}
          control={control}
          InputProps={{
            endAdornment: 'Ha',
          }}
        />
        <NumericHookField
          name={'ancak_amount'}
          label={'Jumlah Ancak'}
          control={control}
          InputProps={{
            startAdornment: 'Ancak\u00a0',
          }}
        />
        <Unstable_Grid2 container gap={2}>
          <Unstable_Grid2 xs>
            <TimeHookField
              name={'drs_started_at'}
              label={'Mulai Deres'}
              control={control}
            />
          </Unstable_Grid2>
          <Unstable_Grid2 xs>
            <TimeHookField
              name={'drs_ended_at'}
              label={'Selesai Deres'}
              control={control}
            />
          </Unstable_Grid2>
        </Unstable_Grid2>
        <CloneTypesHookField
          name={'clone_type_id'}
          label={'Jenis KLON'}
          control={control}
        />
        <DateHookField
          name={'planting_year'}
          label={'Tahun Tanam'}
          control={control}
          views={['year']}
          format={'YYYY'}
          disableFuture
        />
        <NumericHookField
          name={'principal_amount'}
          label={'Jumlah Pokok'}
          control={control}
        />
        <LoadingButton
          sx={{marginY: 1, textTransform: 'none'}}
          color={'primary'}
          variant={'contained'}
          disabled={!isValid}
          fullWidth
          type={'submit'}
        >
          Selanjutnya
        </LoadingButton>
        <Button sx={{textTransform: 'none'}} onClick={prevButtonHandler}>
          Kembali
        </Button>
      </Stack>
      <DevTool control={control} />
    </form>
  );
};
