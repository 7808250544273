import {Visibility, VisibilityOff} from '@mui/icons-material';
import {IconButton, InputAdornment, TextFieldProps} from '@mui/material';
import * as React from 'react';
import {useState} from 'react';
import {Control, FieldValues, Path} from 'react-hook-form';

import TextHookField from '@/components/Form/TextHookField';

export const PasswordHookField = <TFieldValues extends FieldValues>({
  control,
  label,
  name,
  ...rest
}: {
  control: Control<TFieldValues>;
  label: string;
  name: Path<TFieldValues>;
} & TextFieldProps) => {
  const [pwVisible, setPwVisible] = useState(false);

  const passwordVisibleHandler = () => {
    setPwVisible((prevState) => !prevState);
  };

  return (
    <TextHookField
      control={control}
      name={name}
      label={label}
      type={pwVisible ? 'text' : 'password'}
      autoComplete={'current-password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={passwordVisibleHandler}
              edge="end"
              tabIndex={-1}
            >
              {pwVisible ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...rest}
    />
  );
};
