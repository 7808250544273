import {CacheProvider} from '@emotion/react';
import {WrapRootElementBrowserArgs} from 'gatsby';
import * as React from 'react';

import getEmotionCache from './getEmotionCache';

const cache = getEmotionCache();

export const wrapRootElement = ({element}: WrapRootElementBrowserArgs) => {
  return <CacheProvider value={cache}>{element}</CacheProvider>;
};
