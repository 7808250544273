import {DevTool} from '@hookform/devtools';
import {zodResolver} from '@hookform/resolvers/zod';
import {LoadingButton} from '@mui/lab';
import {Stack, Typography, Unstable_Grid2} from '@mui/material';
import {navigate} from 'gatsby';
import React, {useEffect} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import * as z from 'zod';

import {
  LatexStationAutocompleteField,
  RegionAutocompleteField,
  TransactionRegionAutocompleteField,
} from '@/features/regions';
import {CloneTypesHookField} from '@/features/replanting';

import {useDisclosure} from '@/hooks/useDisclosure';

import {
  DateHookField,
  NumericHookField,
  TimeHookField,
} from '@/components/Form';
import {
  FlatAppBar,
  FlatAppBarBackButton,
  FlatAppBarLeft,
  FlatAppBarTitle,
} from '@/components/Layout/FlatAppBar';
import {Layout} from '@/components/Layout/Root';

import {useAuth} from '@/lib/auth';
import dayjs from '@/lib/dayjs';
import {useSnackbarStore} from '@/stores/useSnackbarStore';
import {dayjsOrElse} from '@/utils/dayjs';

import {useUpdateProfile} from '../api/updateProfile';
import {FATokenDialog} from '../components/Dialog/FATokenDialog';


const editRegionSchema = z.object({
  region: z
    .object(
      {
        id: z.string(),
        name: z.string(),
      },
      {
        required_error: 'Region wajib diisi',
      },
    )
    .nullable()
    .transform((val, ctx) => {
      if (val === null) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Region wajib diisi',
        });

        return z.NEVER;
      }

      return val.id;
    }),
  transaction_region: z
    .object(
      {
        id: z.string(),
        name: z.string(),
      },
      {
        required_error: 'Transaction Region wajib diisi',
      },
    )
    .nullable()
    .transform((val, ctx) => {
      if (val === null) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Region wajib diisi',
        });

        return z.NEVER;
      }

      return val.id;
    }),
  latex_station: z
    .object(
      {
        id: z.string(),
        name: z.string(),
      },
      {
        required_error: 'Latex Station wajib diisi',
      },
    )
    .nullable()
    .transform((val, ctx) => {
      if (val === null) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Region wajib diisi',
        });

        return z.NEVER;
      }

      return val.id;
    }),
  land_area: z.coerce
    .number({
      required_error: 'Luas Lahan wajib diisi',
    })
    .gt(0, 'Luas Lahan minimal 1 Ha'),
  ancak_amount: z.coerce
    .number({
      required_error: 'Jumlah Ancak wajib diisi',
    })
    .min(1, 'Jumlah Ancak minimal 1 Ancak'),
  drs_started_at: z
    .instanceof(dayjs as unknown as typeof dayjs.Dayjs)
    .nullable()
    .transform((val) => val?.format('HH:mm:ss')),
  drs_ended_at: z
    .instanceof(dayjs as unknown as typeof dayjs.Dayjs)
    .nullable()
    .transform((val) => val?.format('HH:mm:ss')),
  clone_type: z
    .object({
      id: z.string(),
      name: z.string(),
    })
    .nullish()
    .transform((val) => val?.id),
  planting_year: z
    .instanceof(dayjs as unknown as typeof dayjs.Dayjs)
    .nullable()
    .transform((val) => val?.format('YYYY')),
  principal_amount: z.coerce
    .number({
      required_error: 'Jumlah Pokok wajib diisi',
    })
    .gt(0, 'Jumlah Pokok lebih besar dari 0'),
  token: z
    .string({
      required_error: 'Token wajib diisi',
    })
    .regex(/^\d+$/, 'Token hanya boleh berisikan angka')
    .length(6, 'Token harus 6 karakter'),
});

type EditRegionInputFields = z.input<typeof editRegionSchema>;
type EditRegionOutputFields = z.output<typeof editRegionSchema>;

export const EditRegionPage = () => {
  const {user} = useAuth();

  const faTokenDialog = useDisclosure();
  const snackbarStore = useSnackbarStore();
  const updateProfileMutation = useUpdateProfile();

  const {
    control,
    watch,
    formState: {isDirty},
    setValue,
    getFieldState,
    handleSubmit,
  } = useForm<EditRegionInputFields, unknown, EditRegionOutputFields>({
    mode: 'onBlur',
    resolver: zodResolver(editRegionSchema),
    defaultValues: {
      region: user?.region_data?.region,
      transaction_region: user?.region_data?.sub_region,
      latex_station: user?.region_data?.latex_station,
      land_area: user?.region_data?.land_area ?? 0,
      ancak_amount: user?.region_data?.ancak_amount ?? 1,
      drs_started_at: dayjsOrElse(
        user?.region_data?.drs_started_at,
        'hh:mm:ss',
      ),
      drs_ended_at: dayjsOrElse(user?.region_data?.drs_ended_at, 'hh:mm:ss'),
      clone_type: user?.region_data?.clone_type,
      planting_year: dayjsOrElse(user?.region_data?.planting_year, 'YYYY'),
      principal_amount: user?.region_data?.principal_amount ?? 0,
      token: '',
    },
  });

  const [selectedRegion, selectedTrxRegion] = watch([
    'region',
    'transaction_region',
  ]);

  useEffect(() => {
    if (getFieldState('region').isDirty || !selectedRegion) {
      setValue('transaction_region', null, {
        shouldDirty: true,
      });
    }
  }, [selectedRegion, setValue, getFieldState]);

  useEffect(() => {
    if (getFieldState('transaction_region').isDirty || !selectedTrxRegion) {
      setValue('latex_station', null, {
        shouldDirty: true,
      });
    }
  }, [selectedTrxRegion, setValue, getFieldState]);

  const openFaTokenDialog = () => {
    faTokenDialog.open();
  };

  const onConfirm: SubmitHandler<EditRegionOutputFields> = async (data) => {
    updateProfileMutation.mutate(
      {
        latex_station_id: data?.latex_station,
        land_area: data.land_area,
        ancak_amount: data.ancak_amount,
        drs_started_at: data.drs_started_at,
        drs_ended_at: data.drs_ended_at,
        clone_type_id: data.clone_type,
        planting_year: data.planting_year,
        principal_amount: data.principal_amount,
        token: data.token,
      },
      {
        onSuccess: () => {
          faTokenDialog.close();
          snackbarStore.showSnackbar({
            duration: 3000,
            message: 'Data Region berhasil diubah',
            severity: 'success',
          });

          navigate('/profile', {replace: true});
        },
      },
    );
  };

  return (
    <Layout>
      <FlatAppBar>
        <FlatAppBarLeft>
          <FlatAppBarBackButton onClick={() => navigate('/profile')} />
        </FlatAppBarLeft>
        <FlatAppBarTitle sx={{position: 'absolute'}}>
          Edit Region
        </FlatAppBarTitle>
      </FlatAppBar>
      <FATokenDialog
        open={faTokenDialog.isOpen}
        onClose={() => {
          updateProfileMutation.reset();
          faTokenDialog.close();
        }}
        onConfirm={({token}) => {
          setValue('token', token);
          handleSubmit(onConfirm)();
        }}
      />
      <Stack alignItems={'stretch'} spacing={2}>
        <Typography>
          Isi dan lengkapi data diri anda dan pastikan data sudah benar dan
          sesuai.
        </Typography>
        <RegionAutocompleteField
          name={'region'}
          label={'Region'}
          control={control}
          fullWidth
        />
        <TransactionRegionAutocompleteField
          name={'transaction_region'}
          label={'Transaction Region'}
          control={control}
          regionId={selectedRegion?.id}
          disabled={selectedRegion === null}
          fullWidth
        />
        <LatexStationAutocompleteField
          name={'latex_station'}
          label={'Stasiun Latex'}
          control={control}
          transactionRegionId={selectedTrxRegion?.id}
          disabled={selectedTrxRegion === null}
          fullWidth
        />
        <NumericHookField
          fullWidth
          name={'land_area'}
          label={'Luas Lahan'}
          control={control}
          InputProps={{
            endAdornment: 'Ha',
          }}
        />
        <NumericHookField
          name={'ancak_amount'}
          label={'Jumlah Ancak'}
          control={control}
          InputProps={{
            startAdornment: 'Ancak\u00a0',
          }}
        />
        <Unstable_Grid2 container gap={2}>
          <Unstable_Grid2 xs>
            <TimeHookField
              name={'drs_started_at'}
              label={'Jam Mulai Deres'}
              control={control}
            />
          </Unstable_Grid2>
          <Unstable_Grid2 xs>
            <TimeHookField
              name={'drs_ended_at'}
              label={'Jam Selesai Deres'}
              control={control}
            />
          </Unstable_Grid2>
        </Unstable_Grid2>
        <CloneTypesHookField
          name={'clone_type'}
          label={'Jenis KLON'}
          control={control}
        />
        <DateHookField
          name={'planting_year'}
          label={'Tahun Tanam'}
          control={control}
          format={'YYYY'}
          maxDate={dayjs()}
        />
        <NumericHookField
          name={'principal_amount'}
          label={'Jumlah Pokok'}
          control={control}
        />
        <LoadingButton
          sx={{marginY: 1}}
          onClick={openFaTokenDialog}
          color={'primary'}
          variant={'contained'}
          disabled={!isDirty}
          fullWidth
        >
          Simpan
        </LoadingButton>
      </Stack>
      <DevTool control={control} />
    </Layout>
  );
};
