import * as Sentry from '@sentry/gatsby';
import {useQuery, useQueryClient} from '@tanstack/react-query';
import {navigate} from 'gatsby';
import React, {PropsWithChildren, useContext, useEffect} from 'react';

import {
  AuthResponse,
  LoginCredentials,
  RegisterCredentials,
  login as apiLogin,
  register as apiRegister,
  fetchCurrentUser,
} from '@/features/auth';
import {User} from '@/features/user';

import {SplashScreen} from '@/components/UI/SplashScreen';

import storage from '@/utils/storage';

export interface AuthContextProps {
  user?: User | null;
  login: (credentials: LoginCredentials) => Promise<void>;
  register: (credentials: RegisterCredentials) => Promise<void>;
  logout: () => Promise<void>;
}

export const AuthContext = React.createContext<AuthContextProps | undefined>(
  undefined,
);

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};

export const AuthProvider = ({children}: PropsWithChildren) => {
  const queryClient = useQueryClient();

  /**
   * undefined means the user hasn't been fetched yet
   **/
  const {
    data: user,
    isPending,
    refetch,
  } = useQuery({
    queryKey: ['current-user'],
    queryFn: () => fetchCurrentUser(),
  });

  useEffect(() => {
    if (user) {
      Sentry.setUser({
        id: user?.profile_data?.id,
        username: user?.profile_data?.phone,
        verified: user?.verified,
        subregion_id: user?.region_data?.sub_region?.id,
      });
    }
  }, [user]);

  const login = async ({phone, password}: LoginCredentials) => {
    const response = await apiLogin({phone, password});

    await handleAuthResponse(response);
  };

  const register = async (credentials: RegisterCredentials) => {
    const response = await apiRegister(credentials);

    await handleAuthResponse(response);
  };

  const handleAuthResponse = async (data: AuthResponse) => {
    storage.setToken(data.token);

    await refetch();
  };

  const logout = async () => {
    queryClient.setQueryData(['current-user'], null);
    Sentry.setUser(null);
    storage.clearToken();
    navigate('/login');
  };

  return (
    <AuthContext.Provider value={{user, login, register, logout}}>
      {isPending ? <SplashScreen /> : children}
    </AuthContext.Provider>
  );
};

export const AuthGate = ({
  children,
  redirectIfAuthenticated,
  middleware = 'auth',
}: PropsWithChildren<{
  middleware?: 'auth' | 'guest';
  redirectIfAuthenticated?: string;
}>) => {
  const {user, logout} = useAuth();

  useEffect(() => {
    if (user && middleware === 'guest' && redirectIfAuthenticated) {
      navigate(redirectIfAuthenticated, {replace: true});
    }

    if (!user && middleware === 'auth') {
      logout();
      navigate('/login', {replace: true});
    }
  }, [user]);

  return <>{children}</>;
};
