import {useQuery} from '@tanstack/react-query';

import {LatexStation} from '@/features/regions';

import axios, {ApiResponse} from '@/lib/axios';
import {QueryConfig} from '@/lib/react-query';


const getLatexStations = async (
  transactionRegionId: string,
): Promise<LatexStation[]> => {
  const response = await axios.get<ApiResponse<LatexStation[]>>(
    `/latex-stations/${transactionRegionId}`,
  );

  return response.data.data;
};

type UseLatexStationsOptions = {
  transactionRegionId?: string;
  config?: QueryConfig<typeof getLatexStations>;
};

export const useLatexStations = ({
  transactionRegionId,
  config,
}: UseLatexStationsOptions) => {
  return useQuery({
    queryKey: ['latex-stations', transactionRegionId],
    queryFn: () =>
      transactionRegionId ? getLatexStations(transactionRegionId) : [],
    initialData: [],
    ...config,
  });
};
