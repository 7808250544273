import {Box, Stack, Typography} from '@mui/material';
import {PageProps} from 'gatsby';
import * as React from 'react';

import {WelcomePage} from '@/features/auth';

import {Layout} from '@/components/Layout/Root';

import RegisterStepper from '../components/RegisterStepper';
import {RegisterStepSlug} from '../stores/useRegisterStore';
import {AccountForm} from './RegisterForm/AccountForm';
import {DocumentForm} from './RegisterForm/DocumentForm';
import {ProfileForm} from './RegisterForm/ProfileForm';
import {RegionForm} from './RegisterForm/RegionForm';
import {RoleSelectForm} from './RegisterForm/RoleSelectForm';


export const RegisterPage = (props: Omit<PageProps, 'children'>) => {
  const params = new URLSearchParams(props.location.search);
  const step = params.get('step') as RegisterStepSlug | null;

  const renderForm = () => {
    switch (step) {
      case 'account':
        return <AccountForm />;
      case 'profile':
        return <ProfileForm />;
      case 'region':
        return <RegionForm />;
      case 'document':
        return <DocumentForm />;
      case 'success':
        return <WelcomePage />;
      default:
        return <RoleSelectForm />;
    }
  };

  const renderStep = () => {
    switch (step) {
      case 'account':
        return 0;
      case 'profile':
        return 1;
      case 'region':
        return 2;
      case 'document':
        return 3;
      default:
        return -1;
    }
  };

  return (
    <Layout>
      <Box padding={2}>
        {
          <Stack gap={2}>
            {step && step !== 'success' && (
              <>
                <Typography align="center" fontSize={20} fontWeight={600}>
                  Pendaftaran <span style={{color: '#03b579'}}>Taniyuk</span>
                </Typography>
                <RegisterStepper activeStep={renderStep()} />
              </>
            )}
            {renderForm()}
          </Stack>
        }
      </Box>
    </Layout>
  );
};
