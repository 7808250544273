import {create} from 'zustand';
import {devtools} from 'zustand/middleware';

interface AppUpdateStore {
  hasUpdate: boolean;
  setUpdate: (hasUpdate: boolean) => void;
  toggle: () => void;
}

export const useAppUpdateStore = create<AppUpdateStore>()(
  devtools(
    (set) => ({
      hasUpdate: false,
      setUpdate: (hasUpdate: boolean) => set({hasUpdate}),
      toggle: () => set((state) => ({hasUpdate: !state.hasUpdate})),
    }),
    {
      name: 'AppUpdateStore',
    },
  ),
);
