import {zodResolver} from '@hookform/resolvers/zod';
import {Close} from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import React from 'react';
import {useForm} from 'react-hook-form';
import * as z from 'zod';

import {DateHookField} from '@/components/Form/DateHookField';
import dayjs from "@/lib/dayjs";

const birthDateSchema = z.object({
  birth_date: z.instanceof(dayjs as unknown as typeof dayjs.Dayjs).nullable()
    .nullable(),
});

type EditBirthdateFields = z.infer<typeof birthDateSchema>;

export const EditDateOfBirthDialog = ({
  open,
  onClose,
  onConfirm,
  values,
}: {
  open: boolean;
  onClose: () => void;
  onConfirm: (data: EditBirthdateFields) => void;
  values?: Partial<EditBirthdateFields>;
}) => {
  const {control, handleSubmit} = useForm<EditBirthdateFields>({
    resolver: zodResolver(birthDateSchema),
    defaultValues: {
      birth_date: values?.birth_date,
    },
  });

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={'tw_md'}>
      <DialogTitle>Tanggal Lahir</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => {
          onClose();
        }}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>

      <DialogContent>
        <DateHookField
          name={'birth_date'}
          label={'Tanggal Lahir'}
          control={control}
          disableFuture
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant={'contained'}
          color={'primary'}
          onClick={handleSubmit((data) => {
            onConfirm(data);
          })}
        >
          Simpan
        </Button>
      </DialogActions>
    </Dialog>
  );
};
