import {Box} from '@mui/material';
import * as React from 'react';

import {Image} from '@/components/UI/Image';

import taniyukStackedIcon from '@/assets/images/taniyuk-logo-stacked.svg';

import {Layout} from '../../Layout/Root';

export const SplashScreen = () => (
  <Layout>
    <Box
      sx={{
        display: 'grid',
        height: '100vh',
        placeItems: 'center',
      }}
    >
      <Image
        src={taniyukStackedIcon}
        alt={'Taniyuk Stacked Icon'}
        sx={{
          width: 'auto',
          maxHeight: 180,
        }}
      />
    </Box>
  </Layout>
);
