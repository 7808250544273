import {zodResolver} from '@hookform/resolvers/zod';
import {LoadingButton} from '@mui/lab';
import {Dialog, Stack} from '@mui/material';
import React, {useEffect} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import * as z from 'zod';

import {
  CityAutocompleteField,
  DistrictAutocompleteField,
  ProvinceAutocompleteField,
  RegencyAutocompleteField,
} from '@/features/regions';

import {TextHookField} from '@/components/Form';
import {
  FlatAppBar,
  FlatAppBarBackButton,
  FlatAppBarLeft,
  FlatAppBarTitle,
} from '@/components/Layout/FlatAppBar';

const addressFormSchema = z.object({
  address: z.string().nullable(),
  province: z
    .object({
      id: z.string(),
      name: z.string(),
    })
    .nullable(),
  city: z
    .object({
      id: z.string(),
      name: z.string(),
      province_id: z.string().optional(),
    })
    .nullable(),
  regency: z
    .object({
      id: z.string(),
      name: z.string(),
      city_id: z.string().optional(),
    })
    .nullable(),
  district: z
    .object({
      id: z.string(),
      name: z.string(),
      regency_id: z.string().optional(),
    })
    .nullable(),
});

type AddressFormFields = z.infer<typeof addressFormSchema>;

export const EditAddressDialog = ({
  open,
  onClose,
  onConfirm,
  values,
}: {
  open: boolean;
  onClose: () => void;
  onConfirm: (data: AddressFormFields) => void;
  values?: AddressFormFields;
}) => {
  const {
    formState: {isValid},
    control,
    setValue,
    watch,
    handleSubmit,
    getFieldState,
    reset,
  } = useForm<AddressFormFields>({
    resolver: zodResolver(addressFormSchema),
    defaultValues: {
      address: values?.address,
      province: values?.province,
      city: values?.city,
      regency: values?.regency,
      district: values?.district,
    },
  });

  const [selectedProvince, selectedCity, selectedRegency] = watch([
    'province',
    'city',
    'regency',
  ]);

  useEffect(() => {
    if (getFieldState('province').isDirty || !selectedProvince) {
      setValue('city', null);
    }
  }, [selectedProvince, setValue, getFieldState]);

  useEffect(() => {
    if (getFieldState('city').isDirty || !selectedCity) {
      setValue('regency', null);
    }
  }, [selectedCity, setValue, getFieldState]);

  useEffect(() => {
    if (getFieldState('regency').isDirty || !selectedRegency) {
      setValue('district', null);
    }
  }, [selectedRegency, setValue, getFieldState]);

  const onValid: SubmitHandler<AddressFormFields> = (data) => {
    onConfirm(data);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen
      PaperProps={{
        sx: {
          maxWidth: '28rem',
          marginX: 'auto',
        },
      }}
    >
      <FlatAppBar>
        <FlatAppBarLeft>
          <FlatAppBarBackButton
            onClick={() => {
              reset();
              onClose();
            }}
          />
        </FlatAppBarLeft>
        <FlatAppBarTitle>Alamat</FlatAppBarTitle>
      </FlatAppBar>
      <Stack gap={2} margin={1}>
        <TextHookField
          fullWidth
          name={'address'}
          label={'Alamat'}
          control={control}
          multiline
          minRows={3}
        />
        <ProvinceAutocompleteField
          name={'province'}
          label={'Provinsi'}
          control={control}
        />
        <CityAutocompleteField
          name={'city'}
          label={'Kota'}
          provinceId={selectedProvince?.id}
          control={control}
          fullWidth
        />
        <RegencyAutocompleteField
          name={'regency'}
          label={'Kecamatan'}
          cityId={selectedCity?.id}
          control={control}
          fullWidth
        />
        <DistrictAutocompleteField
          name={'district'}
          label={'Kelurahan/Desa'}
          regencyId={selectedRegency?.id}
          control={control}
          fullWidth
        />

        <LoadingButton
          sx={{marginY: 1}}
          color={'primary'}
          disabled={!isValid}
          variant={'contained'}
          fullWidth
          onClick={handleSubmit(onValid)}
        >
          Simpan
        </LoadingButton>
      </Stack>
    </Dialog>
  );
};
