import {
  Step,
  StepConnector,
  StepIconProps,
  StepLabel,
  Stepper,
  stepConnectorClasses,
  stepLabelClasses,
  styled,
} from '@mui/material';
import PropTypes from 'prop-types';
import * as React from 'react';

const CustomStepLabel = styled(StepLabel)(() => ({
  [`& .${stepLabelClasses.alternativeLabel}`]: {
    [`& .${stepLabelClasses.active}`]: {
      color: 'black',
      fontWeight: 600,
    },
    [`& .${stepLabelClasses.completed}`]: {
      color: 'black',
      fontWeight: 600,
    },
    color: '#4b5563',
    fontSize: '14px',
    fontWeight: 600,
  },
}));

const CustomStepConnector = styled(StepConnector)(({theme}) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: '20%',
    left: 'calc(-50% + 15px)',
    right: 'calc(50% + 15px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
  height: '2px',
  border: 0,
  borderColor: '#ececec',
}));

const CustomStepIcon = (props: StepIconProps) => {
  return (
    <CustomStepIconRoot
      ownerState={{
        active: props.active,
        completed: props.completed,
      }}
    >
      {props.icon}
    </CustomStepIconRoot>
  );
};

const CustomStepIconRoot = styled('div')<{
  ownerState: {active?: boolean; completed?: boolean};
}>(({theme, ownerState}) => ({
  color: '#fff',
  backgroundColor: '#6b7280',
  width: 30,
  height: 30,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...((ownerState.active || ownerState.completed) && {
    backgroundColor: theme.palette.primary.main,
  }),
}));

export const RegisterStepper = ({activeStep}: {activeStep: number}) => {
  const steps = ['Akun', 'Data Diri', 'Region', 'Dokumen'];

  return (
    <Stepper
      activeStep={activeStep}
      alternativeLabel
      connector={<CustomStepConnector />}
    >
      {steps.map((label) => (
        <Step key={label}>
          <CustomStepLabel StepIconComponent={CustomStepIcon}>
            {label}
          </CustomStepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

RegisterStepper.propTypes = {
  activeStep: PropTypes.number.isRequired,
};

export default RegisterStepper;
