import {TimePicker, TimePickerProps} from '@mui/x-date-pickers';
import * as React from 'react';
import {Control, FieldValues, Path, useController} from 'react-hook-form';

type TimeHookFieldProps<T extends FieldValues> = {
  name: Path<T>;
  control: Control<T>;
  label: string;
} & TimePickerProps<unknown>;

export const TimeHookField = <T extends FieldValues>({
  control,
  name,
  label,
}: TimeHookFieldProps<T>) => {
  const {
    field: {onChange, onBlur, value, ref},
    fieldState: {error},
  } = useController({
    name,
    control,
  });

  return (
    <TimePicker
      value={value}
      inputRef={ref}
      onChange={onChange}
      slotProps={{
        textField: {
          name: name,
          label: label,
          margin: 'dense',
          onBlur: onBlur,
          error: !!error,
          fullWidth: true,
          helperText: error?.message ?? '',
        },
      }}
    />
  );
};
