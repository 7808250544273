import {zodResolver} from '@hookform/resolvers/zod';
import {Close} from '@mui/icons-material';
import {LoadingButton} from '@mui/lab';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  formHelperTextClasses,
} from '@mui/material';
import React from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import * as z from 'zod';

import {checkUniqueData} from '@/features/user';

import {TextHookField} from '@/components/Form';


const schema = z.object({
  phone: z
    .string({
      required_error: 'Nomor telepon harus diisi',
    })
    .superRefine(async (value, ctx) => {
      const response = await checkUniqueData({phone: value});

      if (response?.phone) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: response.phone,
        });
      }
    }),
});

type EditPhoneFields = z.infer<typeof schema>;

export const EditPhoneDialog = ({
  open,
  onClose,
  onConfirm,
  values,
}: {
  open: boolean;
  onClose: () => void;
  onConfirm: (values: EditPhoneFields) => void;
  values: Partial<EditPhoneFields>;
}) => {
  const {
    control,
    handleSubmit,
    formState: {isDirty, isSubmitting},
  } = useForm<EditPhoneFields>({
    resolver: zodResolver(schema),
    mode: 'onBlur',
    defaultValues: {
      phone: values?.phone,
    },
  });

  const submitCallback: SubmitHandler<EditPhoneFields> = async (data) => {
    onConfirm(data);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={'tw_md'}>
      <DialogTitle>
        Nomor Telepon
        <IconButton
          aria-label="close"
          onClick={() => {
            onClose();
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TextHookField
          name={'phone'}
          label={'Nomor Telepon'}
          control={control}
          size={'small'}
          fullWidth
          type={'tel'}
          margin={'normal'}
          sx={{
            [`& .${formHelperTextClasses.root}`]: {
              marginX: 0,
            },
          }}
        />
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={isSubmitting}
          variant={'contained'}
          color={'primary'}
          disabled={!isDirty}
          onClick={handleSubmit(submitCallback)}
        >
          Simpan
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
