import {Close} from '@mui/icons-material';
import {Alert, Button, IconButton, Snackbar, Typography} from '@mui/material';
import React from 'react';

import {useAppUpdateStore} from '@/stores/update';

export const UpdateApp = () => {
  const hasUpdate = useAppUpdateStore((state) => state.hasUpdate);

  const handleClose = () => {
    useAppUpdateStore.setState({hasUpdate: false});
  };

  return (
    <Snackbar
      open={hasUpdate}
      onClose={() => {
        handleClose();
      }}
    >
      <Alert
        onClose={handleClose}
        severity="info"
        sx={{width: '100%'}}
        action={
          <>
            <Button onClick={() => window.location.reload()}>Perbaharui</Button>
            <IconButton
              onClick={() => {
                handleClose();
              }}
            >
              <Close fontSize={'small'} />
            </IconButton>
          </>
        }
      >
        <Typography>Ada pembaharuan terbaru pada aplikasi</Typography>
      </Alert>
    </Snackbar>
  );
};
