import api, {ApiResponse} from '@/lib/axios';

type UniqueProfileFields = {
  phone?: string;
  nik?: string;
  npwp?: string;
};

type UniqueProfileMessage = {
  phone?: string;
  nik?: string;
  npwp?: string;
};

export const checkUniqueData = async ({
  phone,
  nik,
  npwp,
}: UniqueProfileFields): Promise<UniqueProfileMessage> => {
  const response = await api.post<ApiResponse<UniqueProfileMessage>>(
    '/data-profile-availability',
    {
      phone,
      nik,
      npwp,
    },
  );

  return response.data.data;
};
