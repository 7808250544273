import {zodResolver} from '@hookform/resolvers/zod';
import {Close} from '@mui/icons-material';
import {LoadingButton} from '@mui/lab';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import * as React from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import * as z from 'zod';

import {checkUniqueData} from '@/features/user';

import {TextHookField} from '@/components/Form';

const validationSchema = z.object({
  nik: z
    .string({
      required_error: 'NIK harus diisi',
    })
    .min(16, 'NIK harus 16 digit')
    .nullable()
    .superRefine(async (value, ctx) => {
      if (!value) return true;

      const uniqueMessage = await checkUniqueData({
        nik: value,
      });

      if (uniqueMessage.nik) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: uniqueMessage.nik,
          path: ['nik'],
        });
      }
    }),
});

type EditNikFields = z.infer<typeof validationSchema>;

export const EditNikDialog = ({
  open,
  onClose,
  onConfirm,
  values,
}: {
  open: boolean;
  onClose: () => void;
  onConfirm: (values: EditNikFields) => void;
  values: Partial<EditNikFields>;
}) => {
  const {
    control,
    handleSubmit,
    formState: {isDirty, isSubmitting},
  } = useForm<EditNikFields>({
    resolver: zodResolver(validationSchema),
    defaultValues: {nik: values.nik},
  });

  const submitCallback: SubmitHandler<EditNikFields> = async (values) => {
    onConfirm(values);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth={'tw_md'} fullWidth>
      <DialogTitle>
        NIK
        <IconButton
          aria-label="close"
          onClick={() => {
            onClose();
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography fontSize={12} fontWeight={400} gutterBottom>
          Isi dengan 16 digit Nomor KTP / Nomor Induk Kependudukan
        </Typography>
        <TextHookField
          name={'nik'}
          label={'NIK'}
          control={control}
          size={'small'}
          margin={'dense'}
          fullWidth
          type={'number'}
          inputProps={{
            maxLength: 16,
            inputMode: 'numeric',
            pattern: '[0-9]*',
          }}
        />
        <DialogActions>
          <LoadingButton
            loading={isSubmitting}
            variant={'contained'}
            disabled={!isDirty}
            onClick={handleSubmit(submitCallback)}
          >
            Simpan
          </LoadingButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
