import {WrapRootElementBrowserArgs} from 'gatsby';
import * as React from 'react';

import {AppProvider} from '@/providers/app';

import {useAppUpdateStore} from './src/stores/update';
import './src/styles/global.css';

export const onServiceWorkerUpdateReady = () => {
  useAppUpdateStore.setState({hasUpdate: true});
};

export const onServiceWorkerUpdateFound = () => {
  window.location.reload();
};

export const wrapRootElement = ({element}: WrapRootElementBrowserArgs) => {
  return <AppProvider>{element}</AppProvider>;
};
