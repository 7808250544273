import {DevTool} from '@hookform/devtools';
import {zodResolver} from '@hookform/resolvers/zod';
import {LoadingButton} from '@mui/lab';
import {Button, CircularProgress, InputAdornment, Stack} from '@mui/material';
import {navigate} from 'gatsby';
import React from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';

import {AccountSchema, accountSchema} from '@/features/auth';
import {FarmerTypesHookField} from '@/features/replanting';

import {PasswordHookField, TextHookField} from '@/components/Form';

import {getPhoneAvailability} from '../../api/getPhoneAvailability';
import {useRegisterStore} from '../../stores/useRegisterStore';


export const AccountForm = () => {
  const {setData, formData, reset} = useRegisterStore();

  const {
    control,
    formState: {isValid, isSubmitting, isValidating},
    handleSubmit,
    setError,
  } = useForm<AccountSchema>({
    resolver: zodResolver(accountSchema, {
      async: true,
    }),
    mode: 'onBlur',
    defaultValues: {
      name: formData.name,
      phone: formData.phone,
      password: formData.password,
      confirm_password: formData.confirm_password,
      farmer_type_id: formData.farmer_type_id,
    },
  });

  const prevButtonHandler = () => {
    reset();
    navigate('/register');
  };

  const submitHandler: SubmitHandler<AccountSchema> = async (data) => {
    const isUnique = await getPhoneAvailability(data.phone);

    if (isUnique) {
      setData(data);
      navigate('?step=profile');
    } else {
      setError('phone', {
        message: 'Nomor telepon sudah terdaftar',
        type: 'server',
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <Stack alignItems={'stretch'} spacing={2}>
        <TextHookField
          name={'name'}
          label={'Nama Lengkap'}
          control={control}
          fullWidth
          autoComplete={'name'}
        />
        <TextHookField
          name={'phone'}
          label={'Nomor Telepon'}
          type={'tel'}
          placeholder={'08xxx'}
          inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}
          control={control}
          autoComplete={'username'}
          InputProps={{
            endAdornment: isValidating && (
              <InputAdornment position={'end'}>
                <CircularProgress size={16} />
              </InputAdornment>
            ),
          }}
          fullWidth
        />
        <PasswordHookField
          name={'password'}
          label={'Password'}
          autoComplete={'new-password'}
          control={control}
          helperText={'Password minimal 8 karakter'}
          fullWidth
        />
        <PasswordHookField
          name={'confirm_password'}
          label={'Konfirmasi Password'}
          autoComplete={'new-password'}
          control={control}
          fullWidth
        />
        <FarmerTypesHookField
          name={'farmer_type_id'}
          label={'Jenis Petani'}
          control={control}
        />
        <LoadingButton
          sx={{marginY: 1, textTransform: 'none'}}
          color={'primary'}
          variant={'contained'}
          disabled={!isValid}
          loading={isSubmitting}
          fullWidth
          type={'submit'}
        >
          Selanjutnya
        </LoadingButton>
        <Button sx={{textTransform: 'none'}} onClick={prevButtonHandler}>
          Kembali
        </Button>
      </Stack>
      <DevTool control={control} />
    </form>
  );
};
