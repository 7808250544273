import Axios from 'axios';

import storage from '../utils/storage';

const api = Axios.create({
  baseURL: `${process.env.GATSBY_API_URL}/`,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(
  (config) => {
    const token = storage.getToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

api.interceptors.response.use(null, (error) => {
  if (error.response) {
    switch (error.response.status) {
      case 401:
        return Promise.reject(
          new ApiResponseException(
            'Unauthenticated',
            error.response.data.message,
          ),
        );
      case 403:
        return Promise.reject(
          new ApiResponseException('Unauthorized', error.response.data.message),
        );
      case 404:
        return Promise.reject(
          new ApiResponseException('NotFound', error.response.data.message),
        );
      case 422:
        return Promise.reject(
          new ApiResponseException(
            'Validation',
            error.response.data.message,
            error.response.data.errors,
          ),
        );
      case 500:
        //TODO: Log to Sentry(?)
        return Promise.reject(
          new ApiResponseException(
            'Internal',
            error.response.data.message,
            error.response.data.errors,
          ),
        );
    }
  } else {
    return Promise.reject(error);
  }
});

export class ApiResponseException<
  TCode extends
    | 'Validation'
    | 'Unauthorized'
    | 'NotFound'
    | 'Internal'
    | 'Unauthenticated',
> extends Error {
  code: TCode;
  errors?: ApiError[] | ApiError;

  constructor(code: TCode, message: string, errors?: ApiError[] | ApiError) {
    super(message);
    this.code = code;
    this.errors = errors;
  }

  isInternalError = () => this.code === 'Internal';

  isUnauthenticatedError = () => this.code === 'Unauthenticated';

  isUnauthorizedError = () => this.code === 'Unauthorized';

  isNotFoundError = () => this.code === 'NotFound';

  isValidationError = () => this.code === 'Validation';
}

export type ApiError = Record<string, string>;

export type ApiResponseMeta = {
  total: number;
  filtered_total: number;
  page: number;
};

export type ApiResponse<T> = {
  meta: ApiResponseMeta;
  message: string;
  data: T;
};

export type ApiPaginationParams = {
  limit?: number;
  offset?: number;
};

export default api;
