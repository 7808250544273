import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import {BottomNavigation, BottomNavigationAction, Paper} from '@mui/material';
import {navigate} from 'gatsby';
import * as React from 'react';
import {useEffect, useState} from 'react';

const BottomNav = () => {
  const [currentPath, setCurrentPath] = useState('');

  useEffect(() => {
    if (/home/.test(window.location.pathname)) {
      setCurrentPath('home');
    }

    if (/profile/.test(window.location.pathname)) {
      setCurrentPath('profile');
    }
  }, [window.location.pathname]);

  return (
    <Paper
      elevation={1}
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        width: '100%',
        maxWidth: '448px',
        marginX: 'auto',
      }}
    >
      <BottomNavigation showLabels value={currentPath}>
        <BottomNavigationAction
          label="Beranda"
          value="home"
          icon={<HomeIcon />}
          onClick={async () => {
            await navigate('/home');
          }}
        />
        <BottomNavigationAction
          label="Profil"
          icon={<PersonIcon />}
          value="profile"
          onClick={async () => {
            await navigate('/profile');
          }}
        />
      </BottomNavigation>
    </Paper>
  );
};
export default BottomNav;
