import {Meta, StoryObj} from '@storybook/react';

import {SplashScreen} from './SplashScreen';

export default {
  title: 'SplashScreen',
  component: SplashScreen,
} satisfies Meta<typeof SplashScreen>;

export const Default = {} satisfies StoryObj<typeof SplashScreen>;
