import {create} from 'zustand';

type SnackbarData = {
  //TODO: Make a stackable Snackbar later
  id?: string;
  message: string;
  severity: 'success' | 'info' | 'warning' | 'error';
  duration: number;
};

type SnackbarStore = {
  snackbar: SnackbarData | null;
  showSnackbar: (snackbar: SnackbarData) => void;
  removeSnackbar: () => void;
};

export const useSnackbarStore = create<SnackbarStore>()((set) => ({
  snackbar: null,
  showSnackbar: (snackbar: SnackbarData) => set(() => ({snackbar})),
  removeSnackbar: () =>
    set(() => ({
      snackbar: null,
    })),
}));
