import {CssBaseline, ThemeProvider} from '@mui/material';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import * as Sentry from '@sentry/gatsby';
import {QueryClientProvider} from '@tanstack/react-query';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import {FlagsProvider} from 'flagged';
import ModalProvider from 'mui-modal-provider';
import React, {PropsWithChildren} from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import {Helmet} from 'react-helmet';

import {ErrorPage} from '@/components/UI/ErrorPage';
import {SnackbarProvider} from '@/components/UI/SnackbarProvider';
import {UpdateApp} from '@/components/UI/UpdateApp';

import features from '@/config/features';
import {muiTheme} from '@/config/theme';
import {AuthProvider} from '@/lib/auth';
import {queryClient} from '@/lib/react-query';

export const AppProvider = (props: PropsWithChildren) => {
  return (
    <>
      <Helmet>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <link
          href="https://fonts.googleapis.com/css?family=Poppins"
          rel="stylesheet"
        />
      </Helmet>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={muiTheme}>
          <ErrorBoundary
            onError={(error) => {
              Sentry.captureException(error);
            }}
            fallbackRender={(props) => {
              return (
                <ErrorPage
                  title={'Terjadi Kesalahan'}
                  description={'Mohon maaf, terjadi kesalahan pada sistem.'}
                  error={props.error}
                  showErrorDetail={true}
                />
              );
            }}
          >
            <CssBaseline />
            <SnackbarProvider />
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale={'id'}
            >
              <ModalProvider>
                <AuthProvider>
                  <FlagsProvider features={features}>
                    <UpdateApp />
                    {props.children}
                  </FlagsProvider>
                </AuthProvider>
              </ModalProvider>
            </LocalizationProvider>
          </ErrorBoundary>
        </ThemeProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </>
  );
};
