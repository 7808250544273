import {TextField} from '@mui/material';
import * as React from 'react';
import {Control, FieldValues, Path} from 'react-hook-form';

import {Region, useRegions} from '@/features/regions';

import {AutocompleteHookField} from '@/components/Form';

export const RegionAutocompleteField = <TFieldValues extends FieldValues>({
  label,
  name,
  control,
  disabled = false,
  ...rest
}: {
  label: string;
  name: Path<TFieldValues>;
  control: Control<TFieldValues>;
  fullWidth?: boolean;
  disabled?: boolean;
}) => {
  const {data: regions, isFetching} = useRegions();

  const optionLabel = (option: Region | string) => {
    if (typeof option === 'string') {
      return option;
    }
    return option.name;
  };

  const isOptionEqualToValue = (option: Region, value: Region) => {
    return option.id === value.id;
  };

  return (
    <AutocompleteHookField
      control={control}
      getOptionLabel={optionLabel}
      loading={isFetching}
      disabled={disabled}
      isOptionEqualToValue={isOptionEqualToValue}
      name={name}
      options={regions ?? []}
      renderInput={(params) => {
        return <TextField label={label} {...params} />;
      }}
      {...rest}
    />
  );
};
