import api, {ApiResponse} from '@/lib/axios';

import {AuthResponse} from '../types';

export type LoginCredentials = {
  phone: string;
  password: string;
};

export const login = async ({
  phone,
  password,
}: LoginCredentials): Promise<AuthResponse> => {
  const response = await api.post<ApiResponse<AuthResponse>>('/login', {
    phone,
    password,
  });
  return response.data.data;
};
