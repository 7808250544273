import {Alert, Snackbar} from '@mui/material';
import React from 'react';

import {useSnackbarStore} from '@/stores/useSnackbarStore';

export const SnackbarProvider = () => {
  const {snackbar, removeSnackbar} = useSnackbarStore();

  return (
    <Snackbar
      open={snackbar !== null}
      onClose={removeSnackbar}
      autoHideDuration={snackbar?.duration}
    >
      <Alert severity={snackbar?.severity}>{snackbar?.message}</Alert>
    </Snackbar>
  );
};
