import dayjs, {ConfigType, Dayjs, OptionType} from 'dayjs';

export const dayjsOrElse = (
  date: ConfigType,
  format?: OptionType,
  fallback?: Dayjs,
) => {
  if (date) {
    return dayjs(date, format, true);
  }
  return fallback;
};
