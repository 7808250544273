import {AppBar, AppBarProps, Toolbar} from '@mui/material';
import * as React from 'react';
import {PropsWithChildren} from 'react';

export const FlatAppBar = (props: PropsWithChildren<AppBarProps>) => {
  return (
    <AppBar position="static" elevation={0} sx={{border: 'none'}} {...props}>
      <Toolbar variant={'dense'} disableGutters>
        {props.children}
      </Toolbar>
    </AppBar>
  );
};
