import {Box} from '@mui/material';
import * as React from 'react';
import {PropsWithChildren} from 'react';

function FlatAppBarLeft(props: PropsWithChildren) {
  return (
    <Box flex={'initial'} width={45} justifyContent={'flex-start'}>
      {props.children}
    </Box>
  );
}

export {FlatAppBarLeft};
