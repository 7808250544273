import api, { ApiResponse } from "@/lib/axios";

export const getPhoneAvailability: (phone: string) => Promise<boolean> = async (
  phone: string,
) => {
  const response = await api.post<ApiResponse<boolean>>('/phone-availability', {
    phone,
  });

  return response.data.data;
};
