import {DeleteOutlined} from '@mui/icons-material';
import {Box, Fab, Stack, Typography} from '@mui/material';
import * as React from 'react';
import {ChangeEvent} from 'react';
import {Control, FieldValues, Path, useController} from 'react-hook-form';

import {Image} from '@/components/UI/Image';

export const ImageUploadCard = <T extends FieldValues>({
  name,
  control,
}: {
  name: Path<T>;
  control: Control<T>;
}) => {
  const {
    field: {value, onChange, onBlur},
  } = useController({name, control});

  const isBlob =
    typeof window === 'undefined' ? false : (value as unknown) instanceof Blob;

  const objectUrl = isBlob ? URL.createObjectURL(value) : value;

  const handleDeleteImage = () => {
    if (isBlob) {
      URL.revokeObjectURL(objectUrl);
    }
    onChange(null);
  };

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event?.target?.files?.[0]);
  };

  return (
    <Box
      sx={{
        border: '1px dashed #6B7280',
        borderRadius: '5px',
        position: 'relative',
        width: '100%',
      }}
    >
      {value && (
        <Fab
          size={'small'}
          color={'inherit'}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            border: '1px solid #03B579',
            boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.25)',
          }}
          onClick={handleDeleteImage}
        >
          <DeleteOutlined color={'primary'} />
        </Fab>
      )}
      <label htmlFor={name}>
        <Box
          sx={{
            alignItems: 'center',
            background:
              'linear-gradient(180deg, rgba(3, 181, 121, 0.06) 0%, rgba(3, 181, 121, 0) 100%)',
            display: 'flex',
            height: '100%',
            justifyContent: 'center',
            maxHeight: '200px',
            minHeight: '100px',
            overflow: 'hidden',
          }}
        >
          <input
            id={name}
            name={name}
            onChange={handleImageChange}
            accept={'image/*'}
            type={'file'}
            onBlur={onBlur}
            style={{
              display: 'none',
            }}
          />
          {value ? (
            <Image
              src={objectUrl}
              alt={'Selected Picture'}
              sx={{
                objectFit: 'contain',
                height: 'auto',
                width: '100%',
              }}
            />
          ) : (
            <Stack spacing={1} alignItems={'center'}>
              <Typography variant={'subtitle1'}>
                Klik disini untuk mengambil gambar
              </Typography>
            </Stack>
          )}
        </Box>
      </label>
    </Box>
  );
};
