import {ArrowForwardIos} from '@mui/icons-material';
import {ButtonBase, Typography, Unstable_Grid2} from '@mui/material';
import React from 'react';

import {Image} from '@/components/UI/Image';

type RoleCardProps = {
  roleName: string;
  img: string;
  description: string;
  onClick: () => void;
};

export const RoleCard = (props: RoleCardProps) => {
  return (
    <ButtonBase
      onClick={props.onClick}
      sx={{
        border: '1px solid #F9FAFB',
        borderRadius: '12px',
        boxShadow: '0px 1px 2px rgba(17, 24, 39, 0.05)',
        padding: 2,
      }}
    >
      <Unstable_Grid2 container gap={1}>
        <Unstable_Grid2 xs={'auto'} alignSelf="center">
          <Image
            src={props.img}
            alt={props.roleName}
            loading={'lazy'}
            sx={{
              backgroundColor: '#EEFDF4',
              borderRadius: '10px',
              maxHeight: 50,
              padding: 1,
            }}
          />
        </Unstable_Grid2>
        <Unstable_Grid2
          xs
          container
          direction={'column'}
          sx={{
            textAlign: 'left',
          }}
        >
          <Typography fontSize={16} fontWeight={'bold'}>
            {props.roleName}
          </Typography>
          <Typography fontSize={12}>{props.description}</Typography>
        </Unstable_Grid2>
        <Unstable_Grid2 xs={'auto'} alignSelf={'center'}>
          <ArrowForwardIos />
        </Unstable_Grid2>
      </Unstable_Grid2>
    </ButtonBase>
  );
};
