import dayjs from 'dayjs';
import 'dayjs/locale/id';
import calendar from 'dayjs/plugin/calendar';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.locale('id');
dayjs.extend(calendar);
dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);

export default dayjs;
