import React, {PropsWithChildren} from 'react';

import BottomNav from '@/components/Layout/Home/BottomNav';

import Layout from '../Root/Layout';

export const HomeLayout = ({children}: PropsWithChildren) => {
  return (
    <Layout>
      <div style={{flex: 1, paddingBottom: '56px'}}>{children}</div>
      <BottomNav />
    </Layout>
  );
};
