import {zodResolver} from '@hookform/resolvers/zod';
import {LoadingButton} from '@mui/lab';
import {Stack, Typography} from '@mui/material';
import {Link, navigate} from 'gatsby';
import React from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import * as z from 'zod';

import {LoginCredentials} from '@/features/auth';

import {PasswordHookField, TextHookField} from '@/components/Form';
import {Layout} from '@/components/Layout/Root';
import {Image} from '@/components/UI/Image';

import taniyukHorizontalImg from '@/assets/images/taniyuk-horizontal.png';
import {useAuth} from '@/lib/auth';
import {ApiResponseException} from '@/lib/axios';


const loginSchema = z.object({
  phone: z.string().min(1, 'Nomor Telepon harus diisi'),
  password: z
    .string({
      required_error: 'Password harus diisi',
    })
    .min(6, 'Password minimal 6 karakter'),
});

export const LoginPage = () => {
  const {login} = useAuth();

  const {
    control,
    handleSubmit,
    formState: {isSubmitting, isValid},
  } = useForm<LoginCredentials>({
    resolver: zodResolver(loginSchema),
    mode: 'onBlur',
    defaultValues: {
      phone: '',
      password: '',
    },
  });

  const onValidCallback: SubmitHandler<LoginCredentials> = async ({
    phone,
    password,
  }) => {
    try {
      await login({phone, password});
      await navigate('/home');
    } catch (e) {
      if (e instanceof ApiResponseException && e.isValidationError()) {
        alert(Object.values(e.errors ?? {})[0]);
      } else {
        console.error(e);
      }
    }
  };

  return (
    <Layout>
      <Stack marginX={2} marginTop={4} gap={2}>
        <Image
          src={taniyukHorizontalImg}
          alt={'Taniyuk Icon'}
          sx={{width: '50%'}}
        />
        <div>
          <Typography fontSize={24} fontWeight={600} gutterBottom>
            Selamat Datang!
          </Typography>
          <Typography color={'neutral.500'} fontSize={14} fontWeight={600}>
            Belum punya akun?{' '}
            <Link
              to={'/register'}
              style={{
                color: '#3CB649',
                textDecoration: 'none',
              }}
            >
              Daftar Sekarang
            </Link>
          </Typography>
        </div>
        <Stack
          component="form"
          onSubmit={handleSubmit(onValidCallback)}
          gap={2}
        >
          <TextHookField
            name={'phone'}
            control={control}
            type={'tel'}
            label={'Nomor Telepon'}
            placeholder={'Masukkan Nomor Telepon'}
            autoComplete={'username'}
            fullWidth
          />
          <PasswordHookField
            name={'password'}
            control={control}
            label={'Password'}
            placeholder={'Masukkan Password'}
            fullWidth
          />
          <LoadingButton
            variant={'contained'}
            disabled={!isValid}
            loading={isSubmitting}
            type={'submit'}
            fullWidth
            sx={{
              textTransform: 'none',
            }}
          >
            Login
          </LoadingButton>
          {/*<Link to={'/forget-password'}>*/}
          {/*  Lupa Password?*/}
          {/*</Link>*/}
        </Stack>
      </Stack>
    </Layout>
  );
};
