import {ExpandMore} from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';

import {Layout} from '@/components/Layout/Root';
import {Image} from '@/components/UI/Image';

import errorImg from '@/assets/images/error.png';

export const ErrorPage = ({
  description,
  error,
  showErrorDetail = false,
  title,
}: {
  description?: string;
  error: Error;
  showErrorDetail?: boolean;
  title: string;
}) => {
  return (
    <Layout>
      <Stack marginTop={3} alignItems={'center'} gap={2}>
        <Image
          src={errorImg}
          alt={'Error Image'}
          sx={{
            width: 'auto',
            maxHeight: 200,
          }}
        />
        <Typography fontSize={24} color={'primary'}>
          {title}
        </Typography>
        <Typography
          fontSize={16}
          color="neutral"
          fontWeight={400}
          textAlign={'center'}
        >
          {description || error.message}
        </Typography>
        {showErrorDetail && (
          <Accordion
            sx={{
              width: '100%',
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                Pesan Kesalahan (sertakan pesan ini saat pelaporan)
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{error.message}</Typography>
            </AccordionDetails>
          </Accordion>
        )}
        <Button
          variant={'contained'}
          color={'primary'}
          fullWidth
          onClick={() => {
            window.location.reload();
          }}
          sx={{
            textTransform: 'none',
          }}
        >
          Muat Ulang
        </Button>
      </Stack>
    </Layout>
  );
};
