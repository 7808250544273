import {TextField} from '@mui/material';
import React from 'react';
import {Control, FieldValues, Path} from 'react-hook-form';

import { CloneType, useCloneTypes } from "@/features/replanting";

import {AutocompleteHookField} from '@/components/Form';

export const CloneTypesHookField = <TFieldValues extends FieldValues>({
  name,
  label,
  control,
}: {
  name: Path<TFieldValues>;
  label: string;
  control: Control<TFieldValues>;
}) => {
  const {data, isFetching} = useCloneTypes();

  return (
    <AutocompleteHookField
      name={name}
      control={control}
      options={data}
      loading={isFetching}
      getOptionLabel={(option: CloneType) => option.name}
      isOptionEqualToValue={(option: CloneType, value: CloneType) => option.id === value.id}
      renderInput={(params) => {
        return <TextField label={label} {...params} />;
      }}
    />
  );
};
