import {TextField} from '@mui/material';
import * as React from 'react';
import {Control, FieldValues, Path} from 'react-hook-form';

import {District, useDistricts} from '@/features/regions';

import {AutocompleteHookField} from '@/components/Form';


export const DistrictAutocompleteField = <Fields extends FieldValues>({
  label,
  name,
  control,
  regencyId,
  ...rest
}: {
  label: string;
  regencyId?: string;
  name: Path<Fields>;
  control: Control<Fields>;
  fullWidth?: boolean;
}) => {
  const {data: districts, isFetching} = useDistricts({
    regencyId,
  });

  const isOptionEqualToValue = (option: District, value: District) => {
    return option.id === value.id;
  };

  const getOptionLabel = (option: District) => {
    return option.name;
  };

  return (
    <AutocompleteHookField
      {...rest}
      control={control}
      disabled={regencyId === undefined}
      getOptionLabel={getOptionLabel}
      loading={isFetching}
      isOptionEqualToValue={isOptionEqualToValue}
      name={name}
      options={districts}
      renderInput={(params) => {
        return <TextField label={label} {...params} />;
      }}
    />
  );
};
