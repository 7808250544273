import {TextField, TextFieldProps} from '@mui/material';
import * as React from 'react';
import {Control, Controller, FieldValues, Path} from 'react-hook-form';
import {NumericFormat, NumericFormatProps} from 'react-number-format';

type NumericHookFieldProps<T extends FieldValues> = {
  control: Control<T>;
  name: Path<T>;
} & NumericFormatProps &
  TextFieldProps;

export const NumericHookField = <T extends FieldValues>({
  control,
  name,
  ...rest
}: NumericHookFieldProps<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: {onChange, onBlur, value, ref},
        fieldState: {error},
      }) => {
        return (
          <NumericFormat
            {...rest}
            value={value}
            onValueChange={(values) => {
              onChange(values.value);
            }}
            onBlur={onBlur}
            thousandSeparator="."
            decimalSeparator=","
            allowNegative={false}
            error={!!error}
            getInputRef={ref}
            helperText={error?.message ?? rest.helperText}
            customInput={TextField}
          />
        );
      }}
    />
  );
};
