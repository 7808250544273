import {TextField} from '@mui/material';
import * as React from 'react';
import {Control, FieldValues, Path} from 'react-hook-form';

import {Province, useProvinces} from '@/features/regions';

import {AutocompleteHookField} from '@/components/Form';

export const ProvinceAutocompleteField = <TFieldValues extends FieldValues>({
  label,
  name,
  control,
  ...rest
}: {
  label: string;
  name: Path<TFieldValues>;
  control: Control<TFieldValues>;
  fullWidth?: boolean;
}) => {
  const {data: provinces, isFetching} = useProvinces();

  const isOptionEqualToValue = (option: Province, value: Province) => {
    return option.id === value.id;
  };

  const getOptionLabel = (option: Province) => {
    return option.name;
  };

  return (
    <AutocompleteHookField
      {...rest}
      fullWidth
      control={control}
      getOptionLabel={getOptionLabel}
      loading={isFetching}
      isOptionEqualToValue={isOptionEqualToValue}
      name={name}
      options={provinces ?? []}
      renderInput={(params) => {
        return <TextField label={label} {...params} />;
      }}
    />
  );
};
