import * as Sentry from '@sentry/gatsby';
import {replayIntegration} from '@sentry/gatsby';

Sentry.init({
  environment: process.env.NODE_ENV,
  // enabled: process.env.NODE_ENV === 'production',
  dsn: process.env.SENTRY_DSN,
  debug: process.env.NODE_ENV === 'development',
  release: `${process.env.npm_package_name}@${process.env.npm_package_version}`,
  tracePropagationTargets: ['localhost'],
  integrations: [
    replayIntegration({
      // Additional SDK configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
