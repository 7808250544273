import {useQuery} from '@tanstack/react-query';

import axios, {ApiResponse} from '@/lib/axios';
import {QueryConfig} from '@/lib/react-query';

import {City} from '../types';


const fetchCities = async (provinceId: string): Promise<City[]> => {
  const response = await axios.get<ApiResponse<City[]>>(
    `/address/cities/${provinceId}`,
  );

  return response.data.data;
};

type UseCitiesOptions = {
  provinceId?: string;
  config?: QueryConfig<typeof fetchCities>;
};

export const useCities = ({provinceId, config}: UseCitiesOptions) => {
  return useQuery({
    queryKey: ['cities', provinceId],
    queryFn: () => {
      return provinceId ? fetchCities(provinceId) : [];
    },
    initialData: [],
    ...config,
  });
};
