import {TextField} from '@mui/material';
import React from 'react';
import {Control, FieldValues, Path} from 'react-hook-form';

import {FarmerType, useFarmerTypes} from '@/features/replanting';

import {AutocompleteHookField} from '@/components/Form';

export const FarmerTypesHookField = <TFieldValues extends FieldValues>({
  control,
  label,
  name,
}: {
  control: Control<TFieldValues>;
  name: Path<TFieldValues>;
  label: string;
}) => {
  const {data, isFetching} = useFarmerTypes();

  return (
    <AutocompleteHookField
      name={name}
      control={control}
      options={data ?? []}
      loading={isFetching}
      getOptionLabel={(option: FarmerType) => option.name}
      fullWidth
      isOptionEqualToValue={(option: FarmerType, value: FarmerType) =>
        option.id === value.id
      }
      renderInput={(params) => {
        return <TextField label={label} {...params} margin={'normal'} />;
      }}
    />
  );
};
