import {Backdrop, CircularProgress, Container} from '@mui/material';
import PropTypes from 'prop-types';
import React, {PropsWithChildren} from 'react';

export const Layout = ({
  loader: isLoading = false,
  children,
}: PropsWithChildren<{
  loader?: boolean;
}>) => {
  return (
    <Container
      fixed
      maxWidth={'tw_md'}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        paddingX: 0,
      }}
    >
      <Backdrop
        sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {children}
    </Container>
  );
};

Layout.propTypes = {
  loader: PropTypes.bool,
  children: PropTypes.node,
};

export default Layout;
