import {DevTool} from '@hookform/devtools';
import {zodResolver} from '@hookform/resolvers/zod';
import {LoadingButton} from '@mui/lab';
import {Button, Stack, Typography} from '@mui/material';
import {navigate} from 'gatsby';
import * as React from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';

import {DocumentSchema, documentSchema, registerSchema} from '@/features/auth';

import {ImageUploadCard} from '@/components/Card/ImageUploadCard';
import {CheckboxHookField} from '@/components/Form';

import {useAuth} from '@/lib/auth';

import {useRegisterStore} from '../../stores/useRegisterStore';


export const DocumentForm = () => {
  const {register} = useAuth();

  const {formData, setData} = useRegisterStore();

  const {
    formState: {isValid, isSubmitting},
    control,
    handleSubmit,
  } = useForm<DocumentSchema>({
    resolver: zodResolver(documentSchema),
    defaultValues: {
      ktp: formData.ktp,
      skt: formData.skt,
      skpl: formData.skpl,
      truth_disclaimer: formData.truth_disclaimer,
    },
  });

  const onValidCallback: SubmitHandler<DocumentSchema> = async (values) => {
    setData(values);

    const parsedData = registerSchema.safeParse(formData);

    if (parsedData.success) {
      await register(parsedData.data);

      await navigate('/welcome', {replace: true});
    }
  };

  const prevButtonHandler = () => {
    navigate('?step=region');
  };

  return (
    <>
      <form onSubmit={handleSubmit(onValidCallback)}>
        <Stack spacing={2}>
          {/*{registerError && (*/}
          {/*  <Alert severity={'error'}>*/}
          {/*    {registerError.response?.data?.message}*/}
          {/*    <ul>*/}
          {/*      {Object.entries(registerError?.response?.data?.errors)?.map(*/}
          {/*        ([error, message]) => {*/}
          {/*          return <li key={error}>{message}</li>;*/}
          {/*        },*/}
          {/*      )}*/}
          {/*    </ul>*/}
          {/*  </Alert>*/}
          {/*)}*/}
          <div>
            <Typography fontSize={14} fontWeight={500}>
              {'Kartu Tanda Penduduk (KTP)'}
            </Typography>
            <ImageUploadCard name={'ktp'} control={control} />
          </div>
          <div>
            <Typography fontSize={14} fontWeight={500}>
              {'Surat Keterangan Tanah (SKT)'}
            </Typography>
            <ImageUploadCard name={'skt'} control={control} />
          </div>
          <div>
            <Typography fontSize={14} fontWeight={500}>
              {'Surat Keterangan Pendaftaran Lahan (SKPL)'}
            </Typography>
            <ImageUploadCard name={'skpl'} control={control} />
          </div>
          <CheckboxHookField
            name={'truth_disclaimer'}
            label={
              'Saya menyatakan bahwa data yang saya masukkan adalah benar.'
            }
            control={control}
          />
          <LoadingButton
            sx={{marginY: 1, textTransform: 'none'}}
            color={'primary'}
            variant={'contained'}
            disabled={!isValid}
            fullWidth
            loading={isSubmitting}
            type={'submit'}
          >
            Selesaikan Pendaftaran
          </LoadingButton>
          <Button sx={{textTransform: 'none'}} onClick={prevButtonHandler}>
            Kembali
          </Button>
        </Stack>
      </form>
      <DevTool control={control} />
    </>
  );
};
